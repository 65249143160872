/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Button, Icon } from "components/Component";
import { Spinner } from "reactstrap";
import getTextColor from "components/custom/getTextColor";
import { getTagsByTake } from "services/tags";
import classNames from "classnames";
import React from "react";
import { servicePutAssignTag } from "services/chats";
import { servicePutCampaigns, servicePutCampaignsProspek } from "services/campaign";
import { socketIO } from "services/socket";

import Swal from "sweetalert2";
import { MessagesContext } from "contexts/messagesContext";
import { ChatContext } from "./ChatContext";

const ChatModalTagAssign = (props) => {
  const { cookies, 
    isModalTagAssignOpen, 
    setIsModalTagAssignOpen, 
    chatIds, 
    setChatIds, 
    checkedTag, 
    setCheckedTag, 
    currentChat, 
    setCurrentChat } = props;
    
  const {chatListState, chatListIdState, selectedChatState, 
    filteredChatListState, queryChatFilterState } = useContext(ChatContext);
  const [chatList, setChatList] = chatListState;
  const [chatIdList, setChatIdList] = chatListIdState;
  const [selectedChat, setSelectedChat] = selectedChatState;
  const [filteredChatList, setFilteredChatList] = filteredChatListState;
  const [queryChatFilter, setQueryChatFilter] = queryChatFilterState;

  const { messagesValue, statusValue } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;

  const [search, setSearch] = useState("");
  const [take, setTake] = useState(10);
  const [tagList, setTagList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredTagList, setFilteredTagList] = useState([]);
  const [tagId, setTagId] = useState([]);
  const [tagListChecked, setTagListCheked] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState("");

  const handleCheckboxClicked = (e, newValue) => {
    if (!e.target.checked) {
      setTagListCheked(tagListChecked.filter((element) => element.id !== newValue.id));
      setTagId(tagId.filter((id) => id !== newValue.id));
      return;
    }

    const checkCat = tagListChecked.find((element) => element.tagCategoryId === newValue.tagCategoryId);
    if (checkCat) {
      const removedOldTag = tagListChecked.filter((element) => element.tagCategoryId !== newValue.tagCategoryId);
      const newTagList = [...removedOldTag, newValue];
      const tagIdList = newTagList.map((itm) => itm.id);
      setTagListCheked(newTagList);
      setTagId(tagIdList);
      //e.target.checked = false;
    } else {
      if (e.target.checked) {
        setTagId([...tagId, newValue.id]);
        setTagListCheked([...tagListChecked, newValue]);
      } else {
        setTagId(tagId.filter((id) => id !== newValue.id));
      }
    }
  };

  const refreshTagsList = async () => {
    setLoading(true);
    try {
      // const responseGetUser = await getTagsByTake(cookies.SIDikey, search, take);
      const responseGetUser = await getTagsByTake(cookies.SIDikey, search, 100);
      if (responseGetUser.status === 200) {
        // Check if status code is 200
        let checkedTagData = [] 
        if(checkedTag.length > 0) { 
          checkedTag.forEach((itm) => { 
            const findTargetTag = responseGetUser.data.data.find((tag) => tag.id === itm.id) 
            if(findTargetTag) { 
              checkedTagData.push(findTargetTag) 
            } else {
              checkedTagData.push(itm) 
            }
          })
        }
        const data = responseGetUser.data.data;
        const departmentIDs = currentChat.channel?.department.id ?? 0
        const tagBasedDepartment = data.filter((dt) =>
          dt.departments.some((dept) => departmentIDs.includes(dept.id))
        );
        const finalData = [];
        tagBasedDepartment.forEach((tag) => {
          // Using forEach instead of map
          let isFound = false;
          finalData.forEach((data) => {
            // Using forEach instead of map
            if (data.id === tag.tagCategoryId) {
              data.tags.push(tag);
              isFound = true;
            }
          });

          if (!isFound) {
            const tempCategory = {
              id: tag.tagCategoryId,
              name: tag.tagCategory?.name,
              tags: [],
            };
            tempCategory.tags.push(tag);
            finalData.push(tempCategory);
          }
        });
        setTagList(finalData);
        setTagListCheked(checkedTagData);
        setFilteredTagList(finalData);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoading(false);
    }
  };
  const campaignId = useMemo(() => {
    // Check if chat and chat.tickets exist and if there's at least one ticket
    if (currentChat?.tickets && currentChat.tickets.length > 0) {
      return currentChat.tickets[0].campaignId;
    }
    return null; // or any default value you want to use if there's no campaignId
  }, [currentChat]); // make sure to list dependencies correctly

  const assignTag = async () => {
    if (requestData.length > 0) {
      let responsePutCampaign;
      if (selectedProspect !== "" && campaignId !== null) {
        responsePutCampaign = await servicePutCampaignsProspek(campaignId, cookies.SIDikey, {
          prospek: selectedProspect,
        });
        if (new RegExp(/20+/).test(responsePutCampaign.status)) {
          Swal.fire("Berhasil Assign Prospek", "Prospek to Ticket", "success");
          setTagId([]);
          setTagListCheked([]);
          setRequestData([]);
          setIsModalTagAssignOpen(false);
        } else if (responsePutCampaign.status === 403) {
          Swal.fire("Caution", "Mohon Tambahkan Permission Campaigns di RxFxL Sales", "warning");
        } else {
          Swal.fire("Error Server", "Gagal Assign Prospek", "error");
          console.log("errors", responsePutCampaign);
        }
      } else {
        // console.log(requestData);
        // const resp = {status: 400}
        const resp = await servicePutAssignTag(cookies.SIDikey, requestData);
        if (new RegExp(/20+/).test(resp.status)) {
          Swal.fire("Berhasil Assign Tag", "Tag to List", "success");
          setTagId([]);
          setTagListCheked([]);
          setRequestData([]);
          setIsModalTagAssignOpen(false);

          let tempChat = {...currentChat, tags: tagListChecked}
          setCurrentChat({...tempChat})
          socketIO.emit(
            "admin",
            JSON.stringify({
              type: "tag-update",
              fromMe: true,
              chatId: tempChat.id,
              chat: {
                id: tempChat.id,
                user: tempChat.user,
                userId: tempChat.user.id,
                channel: tempChat.channel
              },
              tags: tagListChecked,
              ticketId: tempChat?.tickets[0].id,
            })
          );
          socketIO.emit(
            tempChat.userId,
            JSON.stringify({
              type: "tag-update",
              fromMe: true,
              chatId: tempChat.id,
              chat: {
                id: tempChat.id,
                user: tempChat.user,
                userId: tempChat.user.id,
                channel: tempChat.channel
              },
              tags: tagListChecked,
              ticketId: tempChat?.tickets[0].id,
            })
          );

        } else if (resp.status === 403) {
          Swal.fire("Caution", "Mohon Tambahkan Permission Campaigns di RxFxL Sales", "warning");
        } else {
          Swal.fire("Error Server", "Gagal Assign Tag", "error");
          console.log("errors", resp);
        }
      }
    } else {
      Swal.fire("Error", "Mohon dipilih", "error");
    }
  };
  useEffect(() => {
    const temp = chatIdList.map((item) => ({
      id: item,
      tagIds: tagListChecked?.map((tag) => {
        return tag.id;
      }),
    }));

    setRequestData(temp);
  }, [chatIdList, tagId]);

  useEffect(() => {

    if (isModalTagAssignOpen === true) {
      refreshTagsList();
      setTagListCheked([...currentChat.tags]);
    } else {
      setTake(10);
    }
  }, [isModalTagAssignOpen, currentChat]);

  useEffect(() => {
    const lastMessage = newMessages.at(-1);
    if(lastMessage?.type === 'tag-update'){
      if(currentChat.id === lastMessage.chatId){
        currentChat.tags = lastMessage.tags;
        setTagListCheked([...lastMessage.tags]);
      }
    }else if (lastMessage?.type === "tag-update-resolve") {
      if(currentChat.id === lastMessage?.chatId){
        setIsModalTagAssignOpen(false);
      }
      return;
    }
  }, [newMessages]);


  useEffect(() => {
    refreshTagsList();
  }, [search]);

  const scrollRef = useRef();

  return (
    <div className={classNames({ "d-none": isModalTagAssignOpen === false }, "modal-tag-main")}>
      <div
        className="modal-tag-overlay"
        onClick={() => {
          setIsModalTagAssignOpen(false);
        }}
      />
      <div className="modal-tag-body px-2 pt-3 pb-2">
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsModalTagAssignOpen(false);
            setSearch("");
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        {currentChat.status === "RESOLVED" ? (
          <>
            <h5 className="resolve-prevent">Chat dalam keadaan resolved.</h5>
            <div className="mb-1"></div>
          </>
        ) : (
          <>
            <div className="mb-4"></div>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="search"></Icon>
              </div>
              <input
                type="text"
                className="form-round form-control"
                id="default-03"
                placeholder="Search Tag Name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div style={{ maxHeight: 350, overflowY: "auto" }} className="mt-2 tag-assign" ref={scrollRef}>
              {filteredTagList.length > 0
                ? filteredTagList.map((item, index) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="text-black py-2 font-weight-bold ">
                          <p>{item.name}</p>
                        </div>
                        {item.tags.map((tag, index) => {
                          return (
                            <div className="card-inner-md" key={index} style={{ cursor: "pointer" }}>
                              <div className="user-card">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="source"
                                    onChange={(e) => {
                                      handleCheckboxClicked(e, tag);
                                      tag?.tagCategory?.name === "prospek"
                                        ? setSelectedProspect(tag.name)
                                        : setSelectedProspect("");
                                    }}
                                    className="mr-2"
                                    checked={tagListChecked?.some((item) => item.id === tag.id)}
                                  />
                                  <span
                                    className="badge rounded-pill text-bg-primary"
                                    style={{
                                      background: tag.color,
                                      color: getTextColor(tag.color),
                                    }}
                                  >
                                    {tag.name}
                                  </span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    );
                  })
                : null}
              {loading ? (
                <div className="d-flex justify-content-center align-item-strecth">
                  <Spinner animation="border" role="status" color="blue" />
                </div>
              ) : null}
            </div>
            <Button
              onClick={assignTag}
              color="primary"
              className="mt-2 mx-auto"
              style={{ width: "80%", justifyContent: "center" }}
            >
              Pilih
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatModalTagAssign;
