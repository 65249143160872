import React, { useCallback } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, Col, Form, FormGroup, Modal, ModalBody, Tooltip } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  RSelect,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { servicePostCampaigns, getLinkOption } from "services/campaign";
import { getChannels } from "services/channel";
import { serviceGetUsersByDeparmentId } from "../../services/users";
import { getChannelIdByDepartementId } from "../../services/channel";
import { getDepartmentsAll } from "../../services/departments";
import { getTagLeadSource } from "services/tags";
import { serviceGetProfile } from "services/settings";

const AddCampaign = ({ ...props }) => {

  const [cookies] = useCookies(["user"]);
  const [linkOption, setLinkOption] = useState([]);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [contactOption, setContactOption] = useState([]);
  const [leadSourceOption, setLeadSourceOption] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [waTargetVal, setWaTargetVal] = useState();
  const [selectedDept, setSelectedDept] = useState("");
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const { errors, register, handleSubmit, control, reset, setValue, setError, clearErrors } = useForm();
  const [currentLink, setCurrentLink] = useState();
  const [dataProfile, setDataProfile] = useState([]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["campaigns"].access || [];

  //const { errors: errorLink, register: registerLink, handleSubmit: handleSubmitLink, setError: setErrorLink, setValue: setValueLink } = useForm();
  const [channelId, setChannelId] = useState();
  let dataSelect = {
    waTarget: [
      { value: "Rotator", label: "Rotator" },
      //{ value: "Single", label: "Single" },
    ],
  };

  const [formData, setFormData] = useState({
    link: "",
    campaignName: "",
    campaignProduct: "",
    departementTarget: "",
    waTarget: "Single",
    waNumber: "",
    message: "",
    active: true,
    shortlink: "",
    leadSourceId: "",
  });

  const refreshContactOption = async () => {
    const responseGetChannels = await getChannels(cookies.SIDikey);
    //setContactOption(responseGetChannels?.data?.data.map((item) => ({ value: item.id, label: item.phoneNumber })));
  };

  const refreshLinkOption = async () => {
    const linkOptionData = await getLinkOption({ token: cookies.SIDikey });
    setLinkOption(linkOptionData);
  };

  //const addLink = async(submitData) => {
  //  const { linkName, description } = submitData;
  //  try{
  //  axios.interceptors.request.use((config) => {
  //    config.headers["Authorization"] = `Bearer ${cookies.SIDikey}`;
  //    config.headers["Content-Type"] = "application/json";
  //    config.headers["AccessControl-Allow-Origin"] = "http://localhost:3000";
  //    return config;
  //  });
  //  const responseCreateLink = await axios.post(process.env.REACT_APP_BASE_URL + "api/links", {
  //      name: linkName,
  //      description: description,
  //    })
  //    console.log("responseCreateLink", responseCreateLink)
  //    return responseCreateLink
  //  } catch (error) {
  //      console.log("error link", error);
  //      return error
  //  }
  //};

  const getProfile = async () => {
    try {
      const responseProfile = await serviceGetProfile(cookies.SIDikey);
      const resultData = responseProfile.data.departments;
      const finalData = resultData.map(department => ({
        id: department.id,
        color: department.color,
        name: department.name,
      }));
      setDataProfile(finalData);
      setIsProfileLoaded(true);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  const refreshDepartmentOption = async () => {
    try {
      const responseGetDepartments = await getDepartmentsAll(
        cookies.SIDikey,
        ["Access_All_Dept"],
        "DESC",
        "department.createdAt"
      );
      let depts = responseGetDepartments.data;
  
      // Hanya filter departemen jika peran termasuk "Access_Own_Dept"
      if (roleFunctions.includes("Access_Own_Dept")) {
        const departmentsIds = dataProfile.map(item => item.id);
        depts = depts.filter(df => departmentsIds.includes(df.id));
      }
  
      console.log("Filtered Departments:", depts);
      setDepartmentOption(depts);
    } catch (error) {
      console.error("Error refreshing department options:", error);
      setDepartmentOption([]);
    }
  };

  const fetchLeadSource = async () => {
    const responseGetLeadSources = await getTagLeadSource(cookies.SIDikey, ["Access_All_Dept"], 0);

    // responseGetLeadSources.data.map((item) => console.log({ value: item.id, label: item.name }));

    setLeadSourceOption(responseGetLeadSources.data.map((item) => ({ value: item.id, label: item.name })));
  };

  const onFormSubmit = async (e) => {
    //const createLink = await addLink({linkName:e.link, description:e.description})
    //if(createLink.statusText !== "Created") {
    //  Swal.fire({
    //        icon: "error",
    //        title: "Unsuccesfull Create Campaign Link",
    //       focusConfirm: false,
    //     });
    //      return
    //}
    if (channelId.id === "" && channelId.phone === "") {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Create Campaign Link",
        text: "Buat channel terlebih dahulu dengan department yang dipilih sekarang",
        focusConfirm: false,
      });
      return;
    }

    let submittedData = {
      linkName: e.link,
      name: e.campaignName,
      product: e.campaignProduct.replace(/\s/g, ""),
      departmentId: e.departementTarget,
      waTarget: e.waTarget,
      channelId: channelId.id,
      message: e.message,
      status: formData.active ? "ACTIVE" : "INACTIVE",
      noWa: "+" + channelId.phone,
      userId: e.waNumber?.value,
      username: e.waNumber?.label,
      leadSourceId: selectedLeadSource,
    };

    setFormData({
      link: e.link,
      campaignProduct: e.campaignProduct.replace(/\s/g, ""),
      departementTarget: e.departementTarget,
      shortlink: "https://astronacci.org/" + e.link,
    });
    const responsePostCampaign = await servicePostCampaigns(cookies.SIDikey, submittedData);
    if (responsePostCampaign.statusText !== "Created") {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Create Campaign Link",
        focusConfirm: false,
      });
      return;
    } else if (responsePostCampaign.statusText === "Conflict") {
      Swal.fire({
        icon: "error",
        title: "Link name already exist",
        focusConfirm: false,
      });
      return false;
    }
    setSuccessModal(true);
    // reset({});
  };

  const onActiveCheck = (e) => {
    setFormData({
      link: formData.link,
      campaignName: formData.campaignName,
      campaignProduct: formData.campaignProduct,
      departementTarget: formData.departementTarget,
      waTarget: formData.waTarget,
      waNumber: formData.waNumber,
      message: formData.message,
      active: !formData.active,
      userId: formData.userId,
      username: formData.username,
    });
  };

  // Effect to initialize data and refresh department options
  useEffect(() => {
    getProfile(); // Fetch profile data
  }, []); 

  useEffect(() => {
    if (isProfileLoaded) {
      refreshDepartmentOption();
      refreshLinkOption();
      refreshContactOption();
      fetchLeadSource();
    }
  }, [isProfileLoaded]); 

  // Department change
  const departementSelected = useCallback(
    async (departmentId) => {
      const responseGetUsers = await serviceGetUsersByDeparmentId(cookies.SIDikey, departmentId.value);
      const channelId = await getChannelIdByDepartementId(cookies.SIDikey, departmentId.value);
      const waTarget = responseGetUsers.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      setContactOption(waTarget);
      if (channelId.data.data.length !== 0) {
        setChannelId({
          phone: channelId.data.data[0].phoneNumber,
          id: channelId.data.data[0].id,
        });
      } else {
        setChannelId({
          phone: "",
          id: "",
        });
      }
      clearErrors("departementTarget");
    },
    [cookies.SIDikey]
  );
  console.log("channelid", channelId);
  const checkLink = useCallback(
    (name) => {
      const uniqLink = linkOption.find((link) => link.name === name);
      if (uniqLink) {
        setError("link", { type: "error", message: "Name link harus unik!" });
      } else {
        clearErrors("link");
        setValue("link", name);
        setCurrentLink(name);
      }
    },
    [clearErrors, linkOption, setError, setValue]
  );

  return (
    <React.Fragment>
      <Head title="Add Campaign" />
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Campaign
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block>
          <Card className="p-4">
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <strong style={{ fontSize: 18 }}>Tambah Campaign</strong>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              <Form key="addData" className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="6" lg="6">
                  <FormGroup>
                    <label className="form-label">Link</label>
                    <input
                      className="form-control"
                      type="text"
                      name="link"
                      placeholder="Nama Link..."
                      ref={register({
                        required: "This field is required",
                      })}
                      onInput={(e) => {
                        const urlEncode = encodeURIComponent(e.target.value);
                        checkLink(urlEncode);
                      }}
                      onPaste={(e) => e.preventDefault()}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    ></input>

                    {errors.link && (
                      <span className="invalid text-danger font-italic" style={{ fontSize: "11px" }}>
                        {errors.link.message}
                      </span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <label className="form-label">Campaign Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="campaignName"
                      defaultValue={formData.campaignName}
                      ref={register({
                        required: "This field is required",
                      })}
                    ></input>
                    {errors.campaignName && <span className="invalid">{errors.campaignName?.message}</span>}
                  </FormGroup>

                  <FormGroup>
                    <label className="form-label">Campaign Product</label>
                    <input
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      name="campaignProduct"
                      defaultValue={formData.campaignProduct}
                      ref={register({
                        required: "This field is required",
                      })}
                      onPaste={(e) => e.preventDefault()}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    ></input>
                    {errors.campaignProduct && <span className="invalid">{errors.campaignProduct?.message}</span>}
                  </FormGroup>

                  <FormGroup>
                    <label className="form-label">Department Target</label>

                    <RSelect
                      name="departementTarget"
                      options={departmentOption?.map((item) => ({ label: item?.name, value: item.id }))}
                      placeholder="Pilih Department"
                      onChange={(e) => {
                        setValue("departementTarget", e.value);
                        departementSelected(e);
                        setSelectedDept(e.value);
                      }}
                      defaultValue={""}
                      {...register("departementTarget", { required: true })}
                    ></RSelect>
                    {errors.departementTarget && !selectedDept && (
                      <span className="text-danger font-italic" style={{ fontSize: "11px" }}>
                        This field is required
                      </span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <label className="form-label">WA Target</label>
                    <RSelect
                      name="waTarget"
                      options={dataSelect.waTarget}
                      placeholder="Pilih target"
                      onChange={(e) => {
                        setValue("waTarget", e.value);
                        setWaTargetVal(e.value);
                      }}
                      {...register("waTarget", { required: true })}
                      defaultValue={""}
                    ></RSelect>
                    {errors.waTarget && !waTargetVal && (
                      <span className="text-danger font-italic" style={{ fontSize: "11px" }}>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                  {waTargetVal === "Single" && (
                    <FormGroup className={formData.waTarget === "Single" ? "" : "d-none"}>
                      <label className="form-label">User</label>
                      <Controller
                        as={RSelect}
                        control={control}
                        rules={{ required: true }}
                        name="waNumber"
                        options={contactOption}
                        // onChange={(e) => waNumberSelected(e)}
                        placeholder="Pilih Nomor WA"
                        defaultValue={formData.waNumber}
                      />
                      {errors.waNumber && (
                        <span className="text-danger font-italic" style={{ fontSize: "11px" }}>
                          This field is required
                        </span>
                      )}
                    </FormGroup>
                  )}
                  <FormGroup>
                    <label className="form-label">Campaign Lead Source</label>
                    <RSelect
                      name="leadSource"
                      options={leadSourceOption?.map((item) => ({ label: item?.label, value: item.value }))}
                      placeholder="Pilih Lead Source"
                      onChange={(e) => {
                        setValue("leadSourceId", e.value);
                        setSelectedLeadSource(e.value);
                      }}
                      {...register("leadSourceId", { required: true })}
                    ></RSelect>
                    {errors.leadSourceId && (
                      <span className="text-danger font-italic" style={{ fontSize: "11px" }}>
                        This field is required
                      </span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="newActive"
                        name="active"
                        defaultChecked={formData.active}
                        defaultValue={formData.active}
                        onChange={onActiveCheck}
                      />
                      <label className="custom-control-label" htmlFor="newActive">
                        Aktif
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <div className="mt-5" style={{ fontSize: 14 }}>
                    <strong>
                      <span>https://astronacci.org/</span>
                      <span style={{ color: "#4461F2" }}>{currentLink}</span>
                    </strong>
                  </div>
                  <FormGroup>
                    <label className="form-label">Pesan Pembuka</label>
                    <textarea
                      className="form-control"
                      name="message"
                      ref={register({
                        required: "This field is required",
                      })}
                    />
                    {errors.message && <span className="invalid">{errors.message.message}</span>}
                  </FormGroup>
                  <Block className="py-3">
                    <Card className="card-bordered card-bluesky p-2">
                      <strong>Pesan pembuka akan muncul saat customer akan memulai percakapan</strong>
                    </Card>
                  </Block>
                </Col>
                <Col size="12">
                  <Link to={`${process.env.PUBLIC_URL + "/campaign"}`}>
                    <Button outline type="button" color="primary" className="mr-3">
                      Batal
                    </Button>
                  </Link>
                  <Button color="primary">Simpan</Button>
                </Col>
              </Form>
            </Block>
          </Card>
        </Block>
        {/*
        <Modal
          isOpen={addLinkModal}
          toggle={() => setAddLinkModal(false)}
          backdrop="static"
          keyboard={false}
          className="modal-dialog-centered"
          size="xs"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setAddLinkModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm" />
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Link</h5>
              <div className="mt-4">
                <Form key="link" className="row gy-4" onSubmit={handleSubmitLink(addLink)}>
                  <Col md="12" xs="12" lg="12">
                    <FormGroup>
                      <label>Nama Link</label>
                      <input
                        className="form-control"
                        type="text"
                        name="linkName"
                        placeholder="Nama Link..."
                        ref={registerLink({ required: "This field is required" })}
                        onInput={(e) => {
                          const urlEncode = encodeURIComponent(e.target.value)
                          setValueLink(urlEncode)
                          checkLink(urlEncode)
                        }}
                        onPaste={(e) => e.preventDefault()}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errorLink.linkName && <span className="invalid">{errorLink.linkName.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12" xs="12" lg="12">
                    <FormGroup>
                      <label>Catatan</label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        placeholder="Tambah Catatan..."
                        ref={registerLink({ required: "This field is required" })}
                      />
                      {errorLink.description && <span className="invalid">{errorLink.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12" xs="12" lg="12">
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
                      */}
        <Modal
          onClosed={() => {}}
          isOpen={successModal}
          toggle={() => {
            setSuccessModal(false);
          }}
        >
          <ModalBody>
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-center text-primary">Yay! your link has been created successfully</h6>
              {/* <div className="d-flex justify-content-center mt-2">
                <a
                  href={formData.shortlink}
                  target="_blank"
                  className={classNames({ "selector-active": toggleLink }, "text-center link-modal-button-selector")}
                  onClick={() => {
                    setToggleLink(true);
                  }}
                  rel="noreferrer"
                >
                  <Icon name="link " style={{ fontSize: "70px" }} />
                  <p className="font-weight-bold" style={{ fontSize: "20px" }}>
                    LINK
                  </p>
                </a>
                <div
                  className={classNames({ "selector-active": !toggleLink }, "text-center link-modal-button-selector")}
                  onClick={() => setToggleLink(false)}
                >
                  <Icon name="qr " style={{ fontSize: "70px" }} />
                  <p className="font-weight-bold" style={{ fontSize: "20px" }}>
                    QR CODE
                  </p>
                </div>
              </div> */}
              {/* <div className="my-5">
                {!toggleLink ? (
                  <>
                    <QRCode value={formData.shortlink} size={225} fgColor="#545454" />{" "}
                  </>
                ) : (
                  <div className="d-flex">
                    <input
                      type="text"
                      value={formData.shortlink.replace(/\s/g, "")}
                      disabled
                      className="rounded-left"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen}
                      toggle={() => setTooltipOpen(false)}
                      target="copy-tooltip"
                    >
                      Copied!
                    </Tooltip>
                    <CopyToClipboard text={formData.shortlink} onCopy={() => setTooltipOpen(true)}>
                      <div className="copy-link-button rounded-right" id="copy-tooltip">
                        <p>Copy Link</p>
                      </div>
                    </CopyToClipboard>
                  </div>
                )}
              </div> */}
              <div className="my-5">
                <QRCode value={formData.shortlink} size={225} fgColor="#545454" />{" "}
                <div className="d-flex mt-5">
                  <input
                    type="text"
                    value={formData.shortlink.replace(/\s/g, "")}
                    disabled
                    className="rounded-left"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    toggle={() => setTooltipOpen(false)}
                    target="copy-tooltip"
                  >
                    Copied!
                  </Tooltip>
                  <CopyToClipboard text={formData.shortlink} onCopy={() => setTooltipOpen(true)}>
                    <div className="copy-link-button rounded-right" id="copy-tooltip">
                      <p>Copy Link</p>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
              <a href="/campaign" className="text-center">
                Simpan <span>{">"}</span>
              </a>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default AddCampaign;
