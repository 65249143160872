import moment from "moment";
import { axiosPrivate } from "../api/axiosCore";
import { axiosCoreAstro } from "../api/axiosCoreAstro";
// import { Cookies } from "react-cookie";

export const getRating = async (token, datePeriod, cardType, departmentId, userId, role) => {
  const basicUrl = `api/dashboards/ticket-total`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    
    let response = null;

    console.log(userId, role)
    
    if(userId && role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&userId=${userId}&limit=100`);
    } else if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&limit=100`);
    } else if(userId && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&userId=${userId}&departmentIds=${departmentId}&limit=100`);
    } else if(userId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&userId=${userId}&limit=100`);
    } else if(departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&cardType=${cardType}&departmentIds=${departmentId}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTotal = async (token, datePeriod, cardType, departmentId, userId, role, selectedDepartement = null) => {
  let basicUrl = `api/dashboards/ticket-total?datePeriod=${datePeriod}&limit=100`;
  
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;

    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}&cardType=${cardType}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}&cardType=${cardType}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}&cardType=${cardType}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}&cardType=${cardType}&departmentIds=${departmentId}&userId=${userId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}&datePeriod=${datePeriod}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTicketSystem = async (token, datePeriod, departmentId, role, startDate=null, endDate=null) => {
  let basicUrl = `api/dashboards/ticket-statistic?`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    if(datePeriod === 'CUSTOM' && (startDate && endDate)){
      basicUrl += `startDate=${startDate}&endDate=${endDate}&`
    }

    let response = null;
    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTotalSalesTarget = async (token, datePeriod, startDate, endDate) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let basicUrl = `api/dashboards/total-sales-target?`;
    if(datePeriod === 'CUSTOM' && (startDate && endDate)){
      basicUrl += `startDate=${startDate}&endDate=${endDate}&`
    }
    let response = await axiosPrivate.get(`${basicUrl}datePeriod=${datePeriod}&limit=100`);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getAstroTotalSalesTarget = async (token, pc, year, month) => {
  try {
    axiosCoreAstro.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/crm/target/${pc}/${year}/${month}&limit=100`;
    let response = await axiosCoreAstro.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardLeadConversion = async (token, departmentId, datePeriod, role) => {
  const basicUrl = `api/dashboards/lead-conversion-rate`;
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;

    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&departmentIds=${departmentId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?datePeriod=${datePeriod}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardTopTraffic = async (token, departmentId, startDate, endDate, role) => {
  const basicUrl = `api/dashboards/top-traffic-source`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    
    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&limit=100`);
    }else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCustomerLeadProspect = async (sort, token, page, take, salesId, startDate, endDate, role, departmentId, type) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/dashboards/customer-lead-prospect`;
    let response = "";
    if (type && (role === "SALES" || role === "CS")) {
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&salesId=${salesId}&type=${type}&startDate=${startDate}&endDate=${endDate}&fo=ticket.updatedAt`
      );
    } else if (!type && (role === "SALES" || role === "CS")){
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&salesId=${salesId}&startDate=${startDate}&endDate=${endDate}&fo=ticket.updatedAt`
      );
    } else if (type && (role === "HEAD")) {
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&type=${type}&startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&fo=ticket.updatedAt`
      );
    } else if (!type && (role === "HEAD")){
      response = await axiosPrivate.get(
        `${basicUrl}?order=${sort}&page=${page}&take=${take}&startDate=${startDate}&endDate=${endDate}&departmentIds=${departmentId}&fo=ticket.updatedAt`
      );
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCampaignOverview = async (token, departmentId, startDate, endDate, campaignId, role) => {
  const basicUrl = `api/dashboards/campaign-overview`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    }else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDashboardCampaignOverviewTotal = async (token, departmentId, startDate, endDate, cardType, campaignId, role) => {
  const basicUrl = `api/dashboards/campaign-overview-total`;
  startDate = moment(startDate).format("YYYY-MM-DD");
  endDate = moment(endDate).format("YYYY-MM-DD");
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    
    if (role.includes("Access_All_Dept") && departmentId) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&limit=100`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&departmentIds=${departmentId}&limit=100`);
    } else {
      response = await axiosPrivate.get(`${basicUrl}?startDate=${startDate}&endDate=${endDate}&cardType=${cardType}&campaignId=${campaignId}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const getDepartmentList = async (token, roleFunctions, departmentIds) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/departments`;
    let response = null;
    if (roleFunctions.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(basicUrl);
    } else{
      response = await axiosPrivate.get(`${basicUrl}?departmentIds=${departmentIds}&limit=100`);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
