import { axiosPrivate } from "api/axiosCore";

export const serviceGetUserLeads = async (token, roleFunctions, departmentId, page, userPerPage, query, userId = "") => {
  roleFunctions = roleFunctions || [];
  departmentId = departmentId || "";

  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const basicUrl = `api/users?take=50&c=OR&f=role.name,role.name&q=SALES,CS&departmentId=${departmentId}`;
    var response = axiosPrivate.get(basicUrl);
    
    response = await axiosPrivate.get(
      basicUrl
    ); //userId
    

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetTicketLeads = async (token, roleFunctions, departmentId, userId, page,
  filterStatus
) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || "";
    let basicUrl = `api/tickets?take=10&page=${page || 1}&excludeBot=true`;
    var response = null;
    basicUrl += `&userId=${userId}`
    basicUrl += `&fo=ticket.updatedAt`
    
    let f = ''
    let c = ''

    // status ticket.status
    // sales ticket.userId
    // address chat.contact.address
    // city chat.contact.address
    // email chat.contact.email
    // phone chat.contact.customerPhone
    
    if(filterStatus !== ''){
        c += `&status=${filterStatus}`
    }

    response = await axiosPrivate.get(basicUrl+f+c); //deptId
    

    return response;
  } catch (error) {
    if (!error.response) {
      console.log(error)
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};