import { dateProcessing } from "./ChatPartials";
import { useContext } from "react";
import { ChatContext } from "./ChatContext";
import { serviceGetChatById } from "services/chats";
import { delay } from "utils/Utils";

export const ListFilterMessage = ({
  cookies,
  result,
  isSearch,
  searchText,
  setSelectedId,
  setContextMessage,
}) => {

  
  const {filteredChatListState, filteredMessageListState} = useContext(ChatContext);
  const [filteredChatList, _] = filteredChatListState;
  const [filteredMessageList, setFilteredMessageList] = filteredMessageListState;
  console.log('result', result);

  return (
    <div className={`mt-2 ${!isSearch ? "d-none" : ""}`}>
      <h6 className="title overline-title-alt">Messages</h6>
      <ul className="chat-list">
        {filteredMessageList.length > 0 ? (
          filteredMessageList.map((message, index) => {
            return (
              <FilterMessageItem
                cookies={cookies}
                key={index}
                data={message}
                searchText={searchText}
                setSelectedId={setSelectedId}
                setContextMessage={setContextMessage}
              />
            );
          })
        ) : (
          <p className="m-3">Load messages..</p>
        )}
      </ul>
    </div>
  );
};

const FilterMessageItem = ({ cookies, data, searchText, setSelectedIds, setContextMessage }) => {
  const { chatListState, chatListIdState, filteredChatListState, selectedIdState } = useContext(ChatContext);
  const [chatList, setChatList] = chatListState;
  const [filteredChatList, setFilteredChatList] = filteredChatListState;
  const [selecetedId, setSelectedId] = selectedIdState;
  const [ _ , setChatIdList] = chatListIdState;

  const clickedChat = async (msg) => {
    let dataChat = null;
    let chatId = msg?.chat?.id ?? msg.id;

    console.log(msg)
    console.log(filteredChatList)
    if(filteredChatList){
      dataChat = filteredChatList.find((item) => item.id === chatId);
    }else{
      dataChat = chatList.find((item) => item.id === chatId);
    }
    if (!dataChat) {
      const singleChat = await serviceGetChatById(cookies.SIDikey, chatId);
      if (singleChat.status === 200) {
        setChatList((chat) => [...chat, singleChat.data]);
        setChatIdList((chatIdList) => [...chatIdList, chatId]);
        setSelectedId(chatId);
      }
    } else {
      setSelectedId(chatId);
    }
    await delay(500);
    setContextMessage(msg);
  };

  const boldText = (text) => {
    try {
      const regex = new RegExp(searchText, "gi");
      return {
        __html: text.replace(regex, function (x) {
          return x.bold();
        }),
      };
    } catch (error) {
      return text
    }
  };

  return (
    <li
      className="chat-item px-2 py-2"
      onClick={(ev) => {
        ev.preventDefault();
        clickedChat(data);
      }}
    >
      <div className="chat-from">
        <div className="name text-truncate pr-2">
          {data.chat.name != null ? data.chat.name : data.chat.customerPhone}
        </div>
        <span className="time">{dateProcessing(data.timestamp)}</span>
      </div>
      <div className="text">
        <div className="mb-1" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <span className="text-truncate pr-2" dangerouslySetInnerHTML={boldText(data.text)}></span>
        </div>
      </div>
    </li>
  );
};
