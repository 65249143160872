/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Badge} from "reactstrap";
import Simplebar from "simplebar-react";
import { Icon, UserAvatar } from "../../../../components/Component";
import getTextColor from "../../../../components/custom/getTextColor";
import { utilNumberPhoneConvention } from "../utils/ChatUtils";
import { utilDateProcessing } from "../utils/ChatUtils";
import { ChatContext } from "../ChatContext";

export const ChatItemSide = ({
    item,
    setIsModalTagAssignOpen,
    setIds,
    clicked,
    currentStatus
  }) => {
    
    const { selectedIdState } = useContext(ChatContext);
    const [selectedId, setSelectedId] = selectedIdState;

    const [cookies] = useCookies(["user"]);
    // const { isSavedArray } = useContext(ChatContext);
    // const [isSaved] = isSavedArray;
    const [hover, setHover] = useState(false);
    const newUser = item.user;
  
    const deptColor = item.channel?.department?.color ?? "#fffff";
    const deptName = item.channel?.department?.name ?? "default";
  
    const userName =
      (newUser?.firstName || item.updatedBy?.firstName) + " " + (newUser?.lastName || item.updatedBy?.lastName);
  
    // UNREAD CONDITION
    let unreadCountElement = null;
    // sini
  
    let currentUnreadCount = 0;
  
    switch(cookies.role.name){
      case "SALES":
        currentUnreadCount = item.unreadCount;
        break
      case "CS":
        currentUnreadCount = item.unreadCountCs ?? item.unreadCountAdmin ?? 0;
        break
      case "SUPER ADMIN":
        currentUnreadCount = item.unreadCountSuper ?? item.unreadCountAdmin;
        break
      case "HEAD":
        currentUnreadCount = item.unreadCountHead ?? item.unreadCountAdmin;
        break
      case "AUDITOR":
        currentUnreadCount = item.unreadCountAuditor ?? item.unreadCountAuditor;
        break
      case "SYSTEM ADMIN":
        currentUnreadCount = item.unreadCountSysAdmin ?? item.unreadCountSysAdmin;
        break
      default:
        currentUnreadCount = item.unreadCountAdmin ?? item.unreadCountAdmin;
        break
    }
  
    // console.log(item.name, currentStatus, currentUnreadCount, item);
    
    if(currentStatus === 'OPENED'){ // jika dia opened
      currentUnreadCount = 0;
    } else if(clicked){
      currentUnreadCount = 0;
    } else if(currentStatus === 'UNOPENED' && currentUnreadCount < 1){
      currentUnreadCount = 1;
    }
  
    if(currentUnreadCount > 0){
      unreadCountElement = (
        <div className="unread-count position-absolute d-flex">
          <span>{currentUnreadCount}</span>
        </div>
      );
    }
    
    return (
      <li
        className={`chat-item ${item.unread ? "is-unread" : ""} w-100`}
        style={{ backgroundColor: selectedId === item.id ? "#fafafa" : "" }}
      >
        <a
          className="chat-link"
          href="#chat-link"
          onClick={(ev) => {
            ev.preventDefault();
            setSelectedId(item.id);
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          {
            <UserAvatar theme={item.theme} icon="user-alt-fill" image={item.image} className="chat-media overflow-hidden">
              {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span> */}
            </UserAvatar>
          }
          <div className="chat-info">
            <div className="chat-from">
              <div className="name text-truncate pr-2">
                {utilNumberPhoneConvention(item?.name ?? item?.customerPhone)}
                {/* {userIsSaved ? item?.name : utilNumberPhoneConvention(item.customerPhone)} */}
              </div>
              <div className="d-flex flex-row">
                <span className="time">{utilDateProcessing(item?.lastMessage?.timestamp)}</span>
                <div>
                  <div
                    style={{ marginInlineStart: "5px" }}
                    className="more-icon"
                    onClick={() => {
                      setIsModalTagAssignOpen(true);
                      setIds([item.id]);
                    }}
                  >
                    <Icon name="downward-ios" className={classNames({ "d-none": !hover })} />
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-context custom-chat-context">
              {item.lastMessage?.fromMe ? (
                <div className="status delivered mr-1 mb-1">
                  <i
                    className={`bi ${
                      item.lastMessage.status === "READ"
                        ? "bi-check2-all text-info"
                        : item.lastMessage.status === "DELIVERED"
                        ? "bi-check2-all"
                        : item.lastMessage.status === "SENT"
                        ? "bi-check2"
                        : item.lastMessage.status === "FAILED"
                        ? "bi-x-circle text-danger"
                        : ""
                    }`}
                  ></i>
                </div>
              ) : (
                ""
              )}
              <div className="text">
                <div className="mb-1 d-inline-flex w-100">
                  <span className={item.lastMessage?.type !== "TEXT" ? "" : "d-none"} style={{ marginRight: "5px" }}>
                    <Icon
                      name={
                        item.lastMessage?.type === "IMAGE"
                          ? "img-fill"
                          : item.lastMessage?.type === "DOCUMENT"
                          ? "file-fill"
                          : item.lastMessage?.type === "VOICE"
                          ? "mic"
                          : item.lastMessage?.type === "LOCATION"
                          ? "map-pin-fill"
                          : item.lastMessage?.type === "CONTACTS"
                          ? "user-fill-c"
                          : item.lastMessage?.type === "VIDEO"
                          ? "video-fill"
                          : item.lastMessage?.type === "STICKER"
                          ? "notify"
                          : ""
                      }
                      className="text-soft"
                      style={{ fontSize: "13px" }}
                    />
                  </span>
                    {(currentUnreadCount > 0) & !item.fromMe && !clicked ? (
                      <p style={{ color: "black" }} className="text-truncate pr-5 font-weight-bold">
                        {
                        item.lastMessage?.type === "INTERACTIVE"
                          ? (() => {
                              const parsedContent = JSON.parse(item.lastMessage?.content || '{}');
                              return parsedContent.button_reply?.title
                                ? parsedContent.button_reply.title
                                : parsedContent.list_reply?.title
                                ? parsedContent.list_reply.title
                                : item.lastMessage?.text || item.lastMessage?.type;
                            })()
                          : item.lastMessage?.text
                          ? item.lastMessage.text
                          : item.lastMessage?.type
                        }
                      </p>
                    ) : (
                      <p className="text-truncate pr-2 font-weight-light">
                          {(() => {
                            if (item.lastMessage?.type === "INTERACTIVE") {
                              try {
                                // Parsing konten JSON
                                const content = JSON.parse(item.lastMessage?.content || '{}');
                                
                                // Cek apakah button_reply ada dan memiliki title
                                if (content.button_reply?.title) {
                                  return content.button_reply.title;
                                }
                                
                                // Cek apakah list_reply ada dan memiliki title
                                if (content.list_reply?.title) {
                                  return content.list_reply.title;
                                }
                                
                                // Jika tidak ada button_reply dan list_reply, kembalikan text atau type
                                return item.lastMessage?.text || item.lastMessage?.type;
                              } catch (error) {
                                return item.lastMessage?.text || item.lastMessage?.type;
                              }
                            }
  
                            // Untuk kasus selain INTERACTIVE
                            if (item.lastMessage?.text === "") {
                              return item.lastMessage?.content?.replace("media/", "");
                            } else {
                              return item.lastMessage?.text || item.lastMessage?.type;
                            }
                          })()}
                      </p>
                    )}
                </div>
              </div>
              <div className="position-relative">
                  <Badge
                    color={`outline-${
                      currentStatus === "RESOLVED"
                        ? "success"
                        : currentStatus === "OPENED"
                        ? "primary"
                        : currentStatus === "PENDING"
                        ? "warning"
                        : currentStatus === "UNOPENED" 
                        ? "danger"
                        : "primary"
                    }`}
                    pill
                  >
                    {/* {item.unreadCount > 0 && !clicked && currentStatus !== "PENDING" && currentStatus !== "RESOLVED"
                      ? "UNOPENED"
                      : currentStatus} */}
                    {currentStatus}
                  </Badge>
                  {unreadCountElement}
              </div>
            </div>
            <Simplebar className="nk-chat-aside-body py-1">
              <div className="badge-list d-flex">
                {
                  <span
                    className="badge rounded-pill text-bg-primary mr-1"
                    style={{
                      background: deptColor,
                      borderColor: "",
                      color: getTextColor(deptName),
                    }}
                  >
                    {deptName}
                  </span>
                }
  
                {/* {
                  item.channel?.department.length > 0 ?
                  item.channel?.department.map((itm) => {
                    return (
                      <span
                      className="badge rounded-pill text-bg-primary mr-1"
                      style={{
                        background: itm.color,
                        borderColor: "",
                        color: getTextColor(itm.color),
                      }}
                      >
                  {itm.name}
                </span>
                    )
                  })
                : ""
                } */}
                <span
                  className="badge rounded-pill text-bg-primary"
                  style={{
                    background: "#f7f5f5",
                    borderColor: "#050505",
                    color: getTextColor("#f7f5f5"),
                  }}
                >
                  {userName}
                </span>
                {item.tags.map((item, idx) => {
                  return (
                    <span
                      key={idx}
                      className="badge rounded-pill text-bg-primary"
                      style={{
                        background: item.color,
                        borderColor: "#050505",
                        color: getTextColor(item.color),
                        margin: "2px",
                      }}
                    >
                      {item.name}
                    </span>
                  );
                })}
  
                {item.tickets && item.tickets[0]?.campaign && (
                  <span
                    className={`badge rounded-pill text-bg-primary ${item.tags.length == 0 ? "ml-1" : ""}`}
                    style={{
                      background: "#f7f5f5",
                      color: "#050505",
                    }}
                  >
                    {item.tickets[0]?.campaign.name}
                  </span>
                )}
              </div>
            </Simplebar>
          </div>
        </a>
  
        {/* <div className="chat-actions">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu right>
              <ul className="link-list-opt no-bdr">
                <li onClick={() => checkBeforeDelete(item.id)}>
                  <DropdownItem
                    tag="a"
                    href="#delete"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Delete
                  </DropdownItem>
                </li>
                <li onClick={() => propAction(item.id, "unread")}>
                  <DropdownItem
                    tag="a"
                    href="#unread"
                    className={item.unread ? "disabled" : ""}
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Mark as Unread
                  </DropdownItem>
                </li>
                <li onClick={() => propAction(item.id, "archive")}>
                  <DropdownItem
                    tag="a"
                    href="#archive"
                    className={item.archive ? "disabled" : ""}
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Archive Message
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div> */}
      </li>
    );
  };