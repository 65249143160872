import { axiosPrivate } from "../api/axiosCore";

export const servicePutSettings = async (token, id, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/settings/" + id, data);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetSetting = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/settings/" + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetAllSetting = async (token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/settings");
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetProfile = async (token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/auth/me");
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceCreateSettings = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const dataJson = JSON.stringify(data);
    const response = await axiosPrivate.post("api/settings", dataJson);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
