import React, { useEffect, useState } from "react";
import Head from "layout/head/Head";
import {
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "components/Component";
import { notificationData } from "./notificationData";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import getExportFile from "services/export";
import { useCookies } from "react-cookie";
import moment from "moment";
import JSZip from "jszip";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
import { serviceGetAllSetting, serviceGetSetting, servicePutSettings } from "services/settings";

const DefaultPeriod = 30;


const Notification = ({ sm, updateSm }) => {
  const [rotatorsettingId, setRotatorSettingId] = useState("");
  const [newNotificationSettingId, setNewNotificationSettingId] = useState("");
  const [forwardMessageSettingId, setForwardMessageSettingId] = useState("");
  const [saveContactSettingId, setSaveContactSettingId] = useState("");

  const [data, setData] = useState(notificationData);
  const [cookies] = useCookies(["user"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [rotatorSales, setRotatorSales] = useState(null);
  const [newNotification, setNewNotification] = useState(null);
  const [forwardMessage, setForwardMessage] = useState(null);
  const [saveContact, setSaveContact] = useState(null);
  const [minuteDB, setMinuteDB] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const initSetting = async () => {
    const response = await serviceGetAllSetting(cookies.SIDikey);

    if(response?.status === 200){

      const rotatorSetting = response.data.data.find((item) => item.code === "rotator_timeout") ?? "5";
      setMinutes(Number(rotatorSetting.value) >= 5 ? Number(rotatorSetting.value) : 5);
      setMinuteDB(Number(rotatorSetting.value));
      if (Number(rotatorSetting.value) !== 0) {
        setRotatorSales(true);
      } else {
        setRotatorSales(false);
      }
      const notificationSetting = response.data.data.find((item) => item.code === "receive_notification") ?? "false";
      if (notificationSetting.value !== "true") {
        setNewNotification(false);
      } else {
        setNewNotification(true);
      }

      const forwardOfflineSetting = response.data.data.find((item) => item.code === "forward_offline_sales") ?? "false";
      if (forwardOfflineSetting.value !== "true") {
        setForwardMessage(false);
      } else {
        setForwardMessage(true);
      }

      const autoSaveSetting = response.data.data.find((item) => item.code === "automatic_saves_contact") ?? "false";
      if (autoSaveSetting.value !== "true") {
        setSaveContact(false);
      } else {
        setSaveContact(true);
      }

      setRotatorSettingId(rotatorSetting?.id);
      setNewNotificationSettingId(notificationSetting?.id);
      setForwardMessageSettingId(forwardOfflineSetting?.id);
      setSaveContactSettingId(autoSaveSetting?.id);
      // const rotatorCronStatus = response.data.data.find((item) => item.code === "rotator_cron_status") ?? "ENDED";
    }
  };

  const handleRotatorSettingChange = async () => {
    if (rotatorSales) {
      const responsePut = await servicePutSettings(cookies.SIDikey, rotatorsettingId, {
        code: "rotator_timeout",
        value: "0",
        description: "Berdasarkan input pengguna, atur rotator ke Sales lain selama 15 menit",
      });
      console.log(responsePut);
    } else {
      const responsePut = await servicePutSettings(cookies.SIDikey, rotatorsettingId, {
        code: "rotator_timeout",
        value: minutes.toString(),
        description: `Berdasarkan input pengguna, atur rotator ke Sales lain selama ${minutes} menit`,
      });
      console.log(responsePut);
    }
  };

  const handleSubmit = () => {
    if(minutes < 5){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Input untuk assign rotator sales minimal 5 menit",
        focusConfirm: false,
      });
    }else{
      Swal.fire({
        title: "Apakah anda yakin untuk perbaharui setting rotator sales?",
        text: `Berdasarkan input pengguna, atur rotator ke Sales lain selama ${minutes} menit`,
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, perbaharui!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const responsePut = await servicePutSettings(cookies.SIDikey, rotatorsettingId, {
              code: "rotator_timeout",
              value: minutes.toString(),
              description: `Berdasarkan input pengguna, atur rotator ke Sales lain selama ${minutes} menit`,
            });
            setMinuteDB(minutes);
            if (responsePut.status === 202) {
              Swal.fire("Berhasil perbaharui Setting", "Setting Rotator Sales Assign", "success");
            } else {
              Swal.fire("Gagal perbaharui Setting", responsePut.message, "error");
            }
          } catch (error) {}
        }
      });
    }
  };
  const handleNewNotificationSettingChange = async () => {
    if (newNotification) {
      const responsePut = await servicePutSettings(cookies.SIDikey, newNotificationSettingId, {
        code: "receive_notification",
        value: "false",
        description: "Akan muncul Notifikasi untuk pesan baru yang ditujukan kepada user",
      });
      console.log(responsePut);
    } else {
      const responsePut = await servicePutSettings(cookies.SIDikey, newNotificationSettingId, {
        code: "receive_notification",
        value: "true",
        description: "Akan muncul Notifikasi untuk pesan baru yang ditujukan kepada user",
      });
      console.log(responsePut);
    }
  };

  const handleForwardMessageSettingChange = async () => {
    if (forwardMessage) {
      const responsePut = await servicePutSettings(cookies.SIDikey, forwardMessageSettingId, {
        code: "forward_offline_sales",
        value: "false",
        description: "Teruskan pesan walau Sales lain dalam kondisi offline",
      });
      console.log(responsePut);
    } else {
      const responsePut = await servicePutSettings(cookies.SIDikey, forwardMessageSettingId, {
        code: "forward_offline_sales",
        value: "true",
        description: "Teruskan pesan walau Sales lain dalam kondisi offline",
      });
      console.log(responsePut);
    }
  };
  const handleSaveContactSettingChange = async () => {
    if (saveContact) {
      const responsePut = await servicePutSettings(cookies.SIDikey, saveContactSettingId, {
        code: "automatic_saves_contact",
        value: "false",
        description:
          "Nama yang disimpan mengikuti nama kontak WhatsApp pemiliknya secara otomatis, tanpa perlu menambahkan secara manual",
      });
      console.log(responsePut);
    } else {
      const responsePut = await servicePutSettings(cookies.SIDikey, saveContactSettingId, {
        code: "automatic_saves_contact",
        value: "true",
        description:
          "Nama yang disimpan mengikuti nama kontak WhatsApp pemiliknya secara otomatis, tanpa perlu menambahkan secara manual",
      });
      console.log(responsePut);
    }
  };

  const [selectedPeriod, setSelectedPeriod] = useState(
    data.map((item) => {
      return {
        function: item.function,
        period: DefaultPeriod,
      };
    })
  );

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (item, val) => {
    const updatedPeriod = selectedPeriod.map((period) => {
      if (period.function !== item) return period;
      return {
        ...period,
        period: val,
      };
    });
    setSelectedPeriod(updatedPeriod);
  };

  const createDateList = (periodStep, period) => {
    let step = 0;
    let newDateTerm = [];

    while (step < periodStep) {
      const toDateStep = step * period;
      const fromDateStep = (step + 1) * period - 1;
      const toDate = moment().subtract(toDateStep, "days").format("YYYY-MM-DD");
      const fromDate = moment().subtract(fromDateStep, "days").format("YYYY-MM-DD");
      const avoidDate = "2023-07-30";

      const fromCalc = moment(fromDate).diff(moment(avoidDate), "days");
      const toCalc = moment(toDate).diff(moment(avoidDate), "days");
      if (fromCalc <= 0 && toCalc >= 0) {
        console.log("has date 30");
        newDateTerm.push(
          {
            fromDate: fromDate !== 0 ? fromDate : moment(avoidDate).subtract(1, "days").format("YYYY-MM-DD"),
            toDate: moment(avoidDate).subtract(1, "days").format("YYYY-MM-DD"),
          },
          {
            fromDate: moment(avoidDate).add(1, "days").format("YYYY-MM-DD"),
            toDate: toCalc !== 0 ? toDate : moment(avoidDate).add(1, "days").format("YYYY-MM-DD"),
          }
        );
      } else {
        newDateTerm.push({
          fromDate: fromDate,
          toDate: toDate,
        });
      }
      step += 1;
    }

    return newDateTerm;
  };
  const zip = new JSZip();
  const fetchFile = async (periodStep, apiEndPoint, period) => {
    let dataFile = [];
    let step = 0;
    const dateList = createDateList(periodStep, period);
    while (step < dateList.length) {
      const getFileUrl = apiEndPoint + `&fromDate=${dateList[step].fromDate}&toDate=${dateList[step].toDate}`;
      const res = await getExportFile(cookies.SIDikey, getFileUrl);
      if (res.status !== 200) {
        dataFile.push({ status: "error", date: `from ${dateList[step].fromDate} - to ${dateList[step].toDate}` });
      } else {
        dataFile.push({ status: "ok" });
        zip.file(dateList[step].fromDate + "_" + dateList[step].toDate + ".xlsx", res.data);
      }
      step += 1;
    }
    return dataFile;
  };

  const downloadFileExport = async (apiEndPoint) => {
    const dataZip = await zip.generateAsync({ type: "blob" });
    const url = window.URL.createObjectURL(dataZip);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `export_${apiEndPoint.function.replace("[Master Data] ", "")}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleDownload = async (item, periodVal) => {
    setLoading(item);
    const apiEndPoint = data.find((dta) => dta.function === item);
    const period = item.includes("Chat") ? 5 : 15;
    const periodStep = periodVal / period;
    const dataFiles = await fetchFile(periodStep, apiEndPoint.api, period);
    const errorExport = dataFiles.filter((data) => data.status === "error");
    if (errorExport.length > 0) {
      setLoading(false);
      Swal.fire({
        title: "Gagal export sebagian tanggal!",
        text: "Lanjutkan export!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, download it!",
      }).then((result) => {
        if (result.isConfirmed) {
          downloadFileExport(apiEndPoint);
        }
      });
      return;
    }

    downloadFileExport(apiEndPoint);
    setLoading(false);
  };

  useEffect(() => {
    initSetting();
  }, []);

  return (
    <React.Fragment>
      <Head title="System Settings - Notification"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Notification Settings</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">Notification</BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <div className="gy-3">
          <div className="g-item">
            <div className="custom-control custom-switch">
              <InputSwitch
                setChecked={setNewNotification}
                onChange={handleNewNotificationSettingChange}
                id="custom-switch"
                checked={newNotification}
                label="Terima Notifikasi Pesan Baru"
                caption="Akan muncul Notifikasi untuk pesan baru yang ditujukan kepada user"
              />
            </div>
          </div>
          <div className="g-item">
            <div className="custom-control custom-switch">
              <InputSwitch
                setChecked={setForwardMessage}
                checked={forwardMessage}
                onChange={handleForwardMessageSettingChange}
                id="custom-switch2"
                label="Teruskan Pesan ke Sales Offline"
                caption="Teruskan pesan walau Sales lain dalam kondisi offline"
              />
            </div>
          </div>
          <div className="g-item">
            <div className="custom-control custom-switch">
              <InputSwitch
                id="custom-switch3"
                setChecked={setSaveContact}
                checked={saveContact}
                onChange={handleSaveContactSettingChange}
                label="Simpan Kontak Otomatis"
                caption={`Nama yang disimpan mengikuti nama kontak WhatsApp pemiliknya secara otomatis, tanpa perlu menambahkan secara manual`}
              />
            </div>
          </div>
          <div className="g-item">
            <div className="custom-control custom-switch">
              <InputSwitch
                onChange={handleRotatorSettingChange}
                id="custom-switch4"
                setChecked={setRotatorSales}
                checked={rotatorSales}
                label="Assign Rotator Sales"
                caption={`Berdasarkan input pengguna, atur rotator ke Sales lain selama ${minutes} menit`}
              />
            </div>
          </div>
          <div className="g-item" style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "boldn" }}>
            <input
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              type="number"
              className="form-control"
              style={{ width: "50px" }}
            />{" "}
            Menit
          </div>
          <Button
            // disabled={minuteDB === minutes || rotatorSales === false}
            disabled={minuteDB === minutes || rotatorSales === false}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </Button>
        </div>
      </BlockContent>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">System Setting</BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <DataTable className="card-stretch">
          <DataTableBody>
            <DataTableHead>
              <DataTableRow className="nk-tb-col-check">
                <span className="sub-text">No</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Function</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Period</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Action</span>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {currentItems.length > 0
              ? currentItems.map((item, index) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="mb">
                        <span className="tb-amount">{item.id}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="tb-amount">{item.function}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="tb-amount">
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                              <span>
                                <span className="d-none d-md-inline">{selectedPeriod[index].period} Days</span>
                              </span>
                              <Icon className="dd-indc" name="chevron-down"></Icon>
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      handleChange(item.function, 30);
                                    }}
                                  >
                                    30 Days
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      handleChange(item.function, 60);
                                    }}
                                  >
                                    60 Days
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      handleChange(item.function, 90);
                                    }}
                                  >
                                    90 Days
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="tb-amount">
                          <Button
                            color="primary"
                            onClick={() => handleDownload(item.function, selectedPeriod[index].period)}
                          >
                            {loading === item.function ? (
                              <Spinner style={{ width: "1rem", height: "1rem" }} />
                            ) : (
                              <Icon name="server" className="mr-1"></Icon>
                            )}
                            Backup
                          </Button>
                        </span>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </DataTableBody>
          <div className="card-inner">
            {currentItems.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </div>
        </DataTable>
      </BlockContent>
    </React.Fragment>
  );
};
export default Notification;
