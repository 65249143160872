import { useEffect, useState, useContext } from "react";
import { Button, Icon, UserAvatar } from "components/Component";
import getTextColor from "components/custom/getTextColor";
import { Modal, ModalBody, TabContent, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { serviceGetUsers, serviceGetUsersByRoleId } from "services/users";
import { delay, findUpper } from "utils/Utils";
import { servicePutAssignChat, servicePutChat } from "services/chats";
import Swal from "sweetalert2";
import { useDebounce } from "use-debounce";
import { serviceSendMessage } from "services/messages";
import { socketIO } from "services/socket";
import SimpleBar from "simplebar-react";
import { MessagesContext } from "contexts/messagesContext";
import { servicePutTickets } from "services/tickets";
import { ChatContext } from "./ChatContext";

const ChatModalAssign = (props) => {
  const {
    chatId,
    lastMessages,
    setMessages,
    isModalAssignOpen,
    setIsModalAssignOpen,
    cookies,
    chat,
    setChat,
    setSelectedId,
    AfterAssign,
    userDetail
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchText] = useDebounce(search, 100);
  const { messagesValue, statusValue, onlineValue } = useContext(MessagesContext);
  const [statusOnlineUsers, setStatusOnlineUsers] = onlineValue;
  
  const {chatListState, salesListState} = useContext(ChatContext);
  const [salesList, setSalesList] = salesListState;
  const [chatList, setChatList] = chatListState;
  const [userList, setUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["departments"].access || [];

  const salesID = "SALES,CS";
  const csID = "CS";

  const refreshUserList = async () => {
    let responseGetSales = null;
    let responseGetCS = null;

    // segregation of duty implementation
    if (roleFunctions.includes("Access_All_Dept")) {
      responseGetSales = await serviceGetUsersByRoleId(cookies.SIDikey, "", salesID);
      // responseGetCS = await serviceGetUsersByRoleId(cookies.SIDikey, "", csID);
    } else {
      responseGetSales = await serviceGetUsersByRoleId(cookies.SIDikey, cookies.departmentIds, salesID);
      // responseGetCS = await serviceGetUsersByRoleId(cookies.SIDikey, cookies.departmentIds, csID);
    }

    if (!responseGetSales) {
      return;
    }

    if (
      (responseGetSales && new RegExp(/20+/).test(responseGetSales?.status)) ||
      (responseGetCS && new RegExp(/20+/).test(responseGetCS?.status))
    ) {
      const data = [...responseGetSales.data.data];
      setUserList(data.filter((item) => item.isActive === true).sort((a, b) => b.status.localeCompare(a.status)));
      setSalesList(data);
    }
  };

  const refreshUserListDept = async (deptId) => {
    let responseGetSales = null;
    let responseGetCS = null;
    // segregation of duty implementation
    responseGetSales = await serviceGetUsersByRoleId(cookies.SIDikey, [deptId ?? chat.channel.departmentId], 'SALES,CS');
    // responseGetCS = await serviceGetUsersByRoleId(cookies.SIDikey, [chat.channel.departmentId], 'CS');

    if (!responseGetSales) {
      return;
    }
    if (
      (responseGetSales && new RegExp(/20+/).test(responseGetSales?.status)) ||
      (responseGetCS && new RegExp(/20+/).test(responseGetCS?.status))
    ) {
      const data = [...responseGetSales.data.data];
      setUserList(data.filter((item) => item.isActive === true).sort((a, b) => b.status.localeCompare(a.status)));
    }
  };

  useEffect(() => {
    console.log('pilihan', selectedUserId);
  }, [selectedUserId]);

  useEffect(() => {
    if (statusOnlineUsers.length === 0) {
      return;
    }

    const uL = userList;
    const uLFinal = [];
    uL.forEach((element) => {
      if (statusOnlineUsers.find((so) => so.id === element.id && so.status === "ONLINE")) {
        element.status = "ONLINE";
      } else if (statusOnlineUsers.find((so) => so.id === element.id && so.status === "OFFLINE")) {
        element.status = "OFFLINE";
      }
      uLFinal.push(element);
    });

    if (!!statusOnlineUsers) {
      setStatusOnlineUsers([]);
    }

    setUserList(
      uLFinal
        .sort((a, b) => b.status.localeCompare(a.status))
        .filter((item) => item.role.name === "SALES" || item.role.name === "CS")
    );
  }, [statusOnlineUsers]);

  const assignChat = async () => {
    if (selectedUserId) {
      const selectedUser = userList.find((item) => item.id === selectedUserId);
      // if user is offline
      if (selectedUser.departments.length === 0) {
        Swal.fire("Gagal", "User Tidak memiliki department", "error");
        setSelectedUserId("");
        return;
      }
      const chatDeptId = chat?.channel?.departmentId ?? chat?.tickets[0]?.departmentId;
      if (!chatDeptId) {
        Swal.fire("Gagal", "Jaringan anda bermasalah, mohon refresh.", "error");
        setSelectedUserId("");
        return;
      }
      if (!selectedUser.departments.find((itm) => itm.id === chatDeptId) && selectedUser.status === "ONLINE") {
        Swal.fire("Gagal", "Department Sales tidak sesuai dengan channel.", "error");
        setSelectedUserId("");
        return;
      }
      // if (selectedUser.status === "OFFLINE") {
      //   Swal.fire("Gagal", "User Sedang Offline", "error");
      //   setSelectedUserId("");
      //   return;
      // }

      const oldUserId = chat.userId;

      let currentStatus = "";

      // currentStatus = 'UNOPENED' ? "OPENED" : item.status;
      switch(cookies.role.name){
        case "SALES":
          currentStatus = chat.status.includes("OPENED") ? "UNOPENED" : chat.status;
          break
        case "CS":
          currentStatus = chat.status.includes("OPENED") ? "UNOPENED" : chat.csStatus;
          break
        default:
          currentStatus = chat.status.includes("OPENED") ? "UNOPENED" : chat.status;
          break
      }

      const resp = await servicePutAssignChat(cookies.SIDikey, chat.id, {
        userId: selectedUserId,
        status: currentStatus,
        csStatus: currentStatus,
        adminStatus: currentStatus,
        headStatus: currentStatus,
        auditorStatus: currentStatus,
        sysAdminStatus: currentStatus,
        unreadCountCs: 1,
        unreadCount: 1,
      });

      if (resp && new RegExp(/20+/).test(resp?.status)) {

        chat.user = userList.find((item) => item.id === selectedUserId);
        chat.userId = selectedUserId;

        // ROLECHAT
        chat.status = currentStatus;
        chat.csStatus = currentStatus;
        chat.headStatus = currentStatus;
        chat.superStatus = currentStatus;
        chat.adminStatus = currentStatus;
        chat.auditorStatus = currentStatus;
        chat.sysAdminStatus = currentStatus;
        
        chat.unreadCountCS = 1;
        chat.unreadCount = 1;
        const dataMessage = {
          type: "PURCHASE",
          text: `Chat ini di ASSIGN untuk ${chat.user.firstName} ${chat.user.lastName}`,
          chatId: chat.id,
          wa_id: chat.customerPhone,
          ticketId: chat.tickets[0].id,
        };

        dataMessage.fromMe = true;
        const respMessage = await serviceSendMessage(cookies.SIDkey, dataMessage);
        setMessages((messages) => [...messages, respMessage.data]);

        await servicePutChat(cookies.SIDikey, chat.id, { salesID: selectedUserId, 
          status: currentStatus, adminStatus:currentStatus, 
          csStatus:currentStatus, headStatus:currentStatus, superStatus:currentStatus , 
          auditorStatus:currentStatus, sysAdminStatus:currentStatus, 
          unreadCount: 0 });        

        await servicePutTickets(chat.tickets[0].id, cookies.SIDkey, {
          salesId: selectedUserId,
          status: currentStatus.includes("OPENED") ? "UNOPENED" : chat.status,
        });

        if (respMessage && new RegExp(/20+/).test(respMessage?.status)) {
          const lastMessage = respMessage.data;
          const message = { ...lastMessage, chat: { ...chat }, tags: chat.tags, chatId: chat.id };
          console.log('message', message);
          // const message = { ...lastMessage, chat: chat, tags: chat.tags, chatId: chat.id };
          socketIO.emit(oldUserId, JSON.stringify({ type: "Notification", work: "Assign", message: message }));
          socketIO.emit(selectedUserId, JSON.stringify({ type: "Notification", work: "Assign", message: message }));
          socketIO.emit("admin", JSON.stringify({ type: "Notification", work: "Assign", message: message }));
        }
        
        setSelectedId(null);
        // AfterAssign();
        handleAssignLocal();
        setSelectedUserId("");
        setIsModalAssignOpen(false);

        Swal.fire("Berhasil", `Assign Chat ke ${chat.user.firstName} ${chat.user.lastName}`, "success");
        await delay(2000);
      } else {
        Swal.fire("Gagal", "Assign Chat", "error");
      }
    }
  };

  const handleAssignLocal = async () => {
    
  };

  const onRefreshClicked = async () => {
    await refreshUserList();
    // setFilteredUsers(userList);
  };

  useEffect(() => {
    // refreshUserList();
    // setFilteredUsers(userList);
  }, []);

  useEffect(() => {
    console.log('searchText',searchText);
    if (searchText !== "") {
      const newUsers = [];
      userList.map((data) => {
        const fullName = data.firstName + " " + data.lastName;
        if (fullName.toLowerCase().includes(searchText.toLowerCase())) {
          newUsers.push(data);
        }
      });

      console.log('newUsers', newUsers);

      setFilteredUsers(newUsers);
    } else {
      refreshUserList();
      setFilteredUsers(userList);
    }
  }, [searchText]);

  // console.log(filteredUsers.length, searchText);
  return (
    <Modal
      isOpen={isModalAssignOpen}
      backdrop="static"
      keyboard={false}
      toggle={() => setIsModalAssignOpen(false)}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsModalAssignOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <Nav tabs>
            <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropDown}>
              <DropdownToggle nav caret>
                Select Department
                <Icon name="caret-down"></Icon>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem 
                  style={{ color: activeTab === 0 ? "#4461F2" : "", cursor: "pointer" }}
                  onClick={() => {
                    setActiveTab(0)
                    refreshUserList()
                    // refreshUserListDept('ALL')
                    // setSelectedDepartment('');
                  }}
                >
                  All Department
                </DropdownItem>
                <DropdownItem divider />

                {userDetail.departments.map((dept, i) => {
                  return <DropdownItem
                      style={{ color: activeTab === i+1 ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        setActiveTab(i+1)
                        refreshUserListDept(dept.id)
                        // setSelectedDepartment(dept.id);
                      }}
                    >
                      {dept.name}
                    </DropdownItem>
                })}
              </DropdownMenu>
            </Dropdown>

            <Button
              color="transparent"
              onClick={(e) => {
                e.preventDefault();
                onRefreshClicked();
              }}
            >
            Refresh
            <Icon className={'ml-1'} name="reload"></Icon> 
          </Button>
          
        </Nav>
        <TabContent activeTab={activeTab}>
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <input
              type="text"
              className="form-round form-control"
              id="default-03"
              placeholder="Search user"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <SimpleBar style={{ maxHeight: "350px" }}>
            {filteredUsers.length && searchText !== "" ? (
              filteredUsers.map((item, index) => {
                return (
                  <div
                    className="card-inner-md"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => (item.id === selectedUserId ? setSelectedUserId("") : setSelectedUserId(item.id))}
                  >
                    <div className="user-card">
                      <Icon
                        key={item.id}
                        className={item.id === selectedUserId ? "mr-1" : "invisible mr-1"}
                        name="check-circle-fill"
                        style={{ fontSize: 18, color: "#4461f2" }}
                      />
                      <UserAvatar theme={item.theme} text={findUpper(item.firstName)}>
                        {item.status === "ONLINE" ? (
                          <span className="status dot dot-lg dot-success"></span>
                        ) : (
                          <span className="status dot dot-lg dot-gray"></span>
                        )}
                      </UserAvatar>
                      <div className="user-info">
                        <span className="lead-text">{item.firstName + " " + item.lastName}</span>
                        <span className="sub-text">{item.email}</span>
                      </div>
                      <div className="user-action">
                        {item.departments.length > 0
                          ? item.departments.map((department) => (
                              <span
                                key={department.id}
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: department.color || "#ffffff",
                                  color: getTextColor(department.color || "#ffffff"),
                                }}
                              >
                                {department.name}
                              </span>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (salesList.length && searchText === "") || (!salesList.length && searchText === "") ? (
              salesList.map((item, index) => {
                return (
                  <div
                    className="card-inner-md"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => (item.id === selectedUserId ? setSelectedUserId("") : setSelectedUserId(item.id))}
                  >
                    <div className="user-card">
                      <Icon
                        key={item.id}
                        className={item.id === selectedUserId ? "mr-1" : "invisible mr-1"}
                        name="check-circle-fill"
                        style={{ fontSize: 18, color: "#4461f2" }}
                      />
                      <UserAvatar theme={item.theme} text={findUpper(item.firstName)}>
                        {item.status === "ONLINE" ? (
                          <span className="status dot dot-lg dot-success"></span>
                        ) : (
                          <span className="status dot dot-lg dot-gray"></span>
                        )}
                      </UserAvatar>
                      <div className="user-info">
                        <span className="lead-text">{item.firstName + " " + item.lastName}</span>
                        <span className="sub-text">{item.email}</span>
                      </div>
                      <div className="user-action">
                        {item.departments.length > 0
                          ? item.departments.map((department) => (
                              <span
                                key={department.id}
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: department.color || "#ffffff",
                                  color: getTextColor(department.color || "#ffffff"),
                                }}
                              >
                                {department.name}
                              </span>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="mt-4">No user matched</p>
            )}
          </SimpleBar>
          <Button
            color="primary"
            className="mt-2"
            style={{ width: "100%", justifyContent: "center" }}
            onClick={(e) => {
              e.preventDefault();
              assignChat();
            }}
          >
            Assign
          </Button>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ChatModalAssign;
