import moment from "moment";

export const utilNumberPhoneConvention = (number) =>{

    const phonePattern = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d-.\s]{7,15}$/;

    if(!phonePattern.test(number)){
        return number;
    }

    let fN = '+';
    fN = fN + number.slice(0,2) +' ';
    fN = fN + number.slice(2,5) + '-';
    fN = fN + number.slice(5,9) + '-';
    fN = fN + number.slice(9,);

    return fN
}

export const utilDateProcessing = (timestamp) => {
  const today = moment().format("DD/MM/YYYY");
  const compared = moment.unix(timestamp / 1000).format("DD/MM/YYYY");

  if (today === compared) {
    return new Date(Number(timestamp)).toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  } else if (moment().subtract(1, "day").format("DD/MM/YYYY") === compared) {
    return "Yesterday";
  }
  return new Date(Number(timestamp)).toLocaleString("en-GB", {
    month: "numeric",
    year: "numeric",
    day: "numeric",
  });
};

export const combine2ChatNoDuplicate = (array1, array2) => {
  const combined = [
    ...array1,
    ...array2.filter(chatItem => 
      !array1.some(filteredItem => filteredItem.id === chatItem.id)
    )
  ];

  return combined;
};

export const chatNoDuplicate = (array) => {
  const uniqueArray2 = array.filter((item, index, self) => {
    return index === self.findIndex(t => t.id === item.id);
  });

  return uniqueArray2;
};

export const addChatWithoutDuplication = (newChat, chatList) => {
  if (!newChat || !newChat.id) {
    console.error("New chat must have an id property");
    return chatList;
  }

  // Create a copy of the list to avoid mutating the original
  const updatedList = [...chatList];
  
  // Find the index of any existing chat with the same ID
  const existingIndex = updatedList.findIndex(chat => chat.id === newChat.id);
  
  // If found, remove the existing chat
  if (existingIndex !== -1) {
    updatedList.splice(existingIndex, 1);
  }
  
  // Add the new chat at the beginning
  updatedList.unshift(newChat);
  
  return updatedList;
}