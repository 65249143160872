import React from "react";
import LeadsMonitoringLine from "./partial/LeadsMonitoringLine";
import { Spinner } from "reactstrap";

const LeadsMonitoringBody = ({ userList, loading, kanbanFilter, setLoadMoreId, setTicketMetaData }) => {

  userList.sort((a, b) => {
    let fa = a.ticketCount,
      fb = b.ticketCount;
    if (fa > fb) {
      return -1;
    }
    if (fa < fb) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
        {loading ? <Spinner/> :
        userList.map((user, index) => { return <LeadsMonitoringLine key={index} index={index} user={user} kanbanFilter={kanbanFilter} 
        setLoadMoreId={setLoadMoreId} setTicketMetaData={setTicketMetaData} />})}
    </div>
  );
};

export default LeadsMonitoringBody;
