import React, { useState, useEffect } from "react";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import { tagList, ticketSystemData } from './homeData'
import { BarChartExample, Icon, Row, Col } from "components/Component";
import TagChart from "pages/home/TagChart";
import { useCookies } from "react-cookie";
import moment from "moment";
// import { DatePicker } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import {
  getDashboardTicketSystem,
  getDashboardTotalSalesTarget,
  getAstroTotalSalesTarget
} from "../../services/dashboard";

const TicketSystem = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [dataTicketSystem, setDataTicketSystem] = useState([]);
  const [dataTotalSalesTarget, setDataTotalSalesTarget] = useState([]);
  const [targetCards, setTargetCards] = useState([]);
  const [targetCard1, setTargetCard1] = useState(0);
  const [targetCard2, setTargetCard2] = useState(0);
  const [targetCard3, setTargetCard3] = useState(0);
  const [targetCard4, setTargetCard4] = useState(0);
  const [targetCard5, setTargetCard5] = useState(0);
  const [dataChart, setDataChart] = useState(
    {
      labels: [],
      stacked: true,
      dataUnit: "USD",
      datasets: []
    }
  );
  const [datePeriod, setDatePeriod] = useState("MONTHLY");
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('YYYY-MM-DD')))
  const [endDate, setEndDate] = useState(new Date(moment().format('YYYY-MM-DD')))
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];


  const getDataTicketSystem = async (datePeriod, sdate=null, edate=null) => {
    console.log(datePeriod, sdate, edate)
    if(datePeriod === 'CUSTOM' && (!sdate || !edate)){
      return
    }

    const formattedStartDate = formatDateForPostgres(sdate) ?? false;
    const formattedEndDate = formatDateForPostgres(edate) ?? false;

    
    const responseTicketSystem = await getDashboardTicketSystem(cookies.SIDikey, datePeriod, selectedDepartment.value, roleFunctions, formattedStartDate, formattedEndDate);
    const responseTotalSalesTarget = await getDashboardTotalSalesTarget(cookies.SIDikey, datePeriod, formattedStartDate, formattedEndDate);
    console.log('AAA', responseTicketSystem)
    
    setDataTicketSystem(responseTicketSystem.data)
    setDataTotalSalesTarget(responseTotalSalesTarget.data)

    setTargetCards([]);
    const targetCardLocal = [];

    if(responseTotalSalesTarget.data){
      responseTotalSalesTarget.data.forEach(async (item) => {
        const responseAstroTotalSalesTarget = await getAstroTotalSalesTarget(cookies.AstroKey, item.pc, moment().format('YYYY'), moment().format('MM'))
        console.log('targetCardLocal', responseAstroTotalSalesTarget)
        targetCardLocal.push(responseAstroTotalSalesTarget.data.data.target ? responseAstroTotalSalesTarget.data.data.target : '0')
      });
    }
    
    setTargetCards(targetCardLocal);
    const arrDataSet = []
    responseTicketSystem.data.datasets.forEach(item => {
      arrDataSet.push({
        label: item.department.name,
        backgroundColor: item.department.color,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        data: item.data
      })
    })
    const arrLabel = []
    responseTicketSystem.data.label.forEach(item => {
      if (datePeriod === 'DAILY'){
        arrLabel.push(moment(item).format('ll'))
      }else if(datePeriod === 'WEEKLY'){
        arrLabel.push(moment(item).format('MMM DD'))
      }else if(datePeriod === 'MONTHLY'){
        arrLabel.push(moment(item).format('MMM'))
      }else if(datePeriod === 'YEARLY'){
        arrLabel.push(moment(item).format('YYYY'))
      }else{
        arrLabel.push(moment(item).format('ll'))
      }
    })
    setDataChart({
      labels: arrLabel,
      stacked: true,
      dataUnit: "USD",
      datasets: arrDataSet
    })
  };

  useEffect(() => {
    getDataTicketSystem(datePeriod)
  }, [selectedDepartment]);

  const handleDatePeriod = (val) => {
    setDatePeriod(val)
    if(val !== 'CUSTOM'){
      getDataTicketSystem(val)
    }
  }

  const formatDateForPostgres = (date) => {
    if (!date) return null;
    
    // Format date as YYYY-MM-DD
    return date.toISOString().split('T')[0];
  };

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    console.log('date changed')
    if(start && end){
      console.log('aa')
      setDatePeriod('CUSTOM');
      getDataTicketSystem('CUSTOM', start, end);
    }
  }

  return (
    <React.Fragment>
      <Card className="p-5">
        <div>
        <div className="nk-ecwg nk-ecwg8 h-100">
          <div className="card-inner">
            <div className="card-title-group mb-3">
              <div className="card-title">
                <h6 className="title">Ticket System</h6>
              </div>
              <div className="card-tools">
                <div className="d-flex flex-column">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="#toggle"
                      onClick={(ev) => ev.preventDefault()}
                      className="link link-light link-sm dropdown-indicator"
                    >
                      {
                        datePeriod === 'DAILY' ?
                          'Daily'
                        : datePeriod === 'WEEKLY' ?
                          'Weekly'
                        : datePeriod === 'MONTHLY' ?
                          'Monthly'
                        : datePeriod === 'YEARLY' ?
                          'Annually'
                        : datePeriod === 'CUSTOM' ?
                          'Custom'
                        : null
                      }
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-sm">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDatePeriod('DAILY');
                            }}
                            className={datePeriod === "DAILY" ? "active" : ""}
                          >
                            <span>Daily</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDatePeriod('WEEKLY');
                            }}
                            className={datePeriod === "WEEKLY" ? "active" : ""}
                          >
                            <span>Weekly</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDatePeriod('MONTHLY');
                            }}
                            className={datePeriod === "MONTHLY" ? "active" : ""}
                          >
                            <span>Monthly</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDatePeriod('YEARLY');
                            }}
                            className={datePeriod === "YEARLY" ? "active" : ""}
                          >
                            <span>Annually</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#dropdown"
                            onClick={(ev) => {
                              ev.preventDefault();
                              handleDatePeriod('CUSTOM');
                            }}
                            className={datePeriod === "CUSTOM" ? "active" : ""}
                          >
                            <span>Custom</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
  {/* Left side content */}
  <div>
    {/* Your other content (e.g., the dropdown) goes here */}
  </div>
  
  {/* Right side content */}
  <div className="custom position-relative w-15 d-flex justify-content-end align-items-end">
    {datePeriod === 'CUSTOM' && (
      <DatePicker
        className="form-control date-picker"
        selectsRange
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDate}
        dateFormat={'dd-MM-yyyy'}
      />
    )}
  </div>
</div>
            <ul className="nk-ecwg8-legends">
              {dataTicketSystem?.datasets?.map((item, index) => (
                <li key={index}>
                  <div className="title">
                    <span className="dot dot-lg sq" style={{ background: item.department.color }}></span>
                    <span>{item.department.name}</span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="nk-ecwg8-ck">
              <BarChartExample stacked data={dataChart} />
            </div>
            <div className="chart-label-group pl-5">
              <div className="chart-label">
                {
                  datePeriod === 'CUSTOM' ?
                    moment(dataTicketSystem?.label?.[0]).format('DD MMM YYYY')
                  : datePeriod === 'DAILY' ?
                    moment(dataTicketSystem?.label?.[0]).format('DD MMM YYYY')
                  : datePeriod === 'WEEKLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('MMM YYYY')
                  : datePeriod === 'MONTHLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('MMM YYYY')
                  : datePeriod === 'YEARLY' ?
                    moment(dataTicketSystem?.label?.[0]).format('YYYY')
                  : null
                }
              </div>
              <div className="chart-label">
                {
                  datePeriod === 'CUSTOM' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('DD MMM YYYY')
                  : datePeriod === 'DAILY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('DD MMM YYYY')
                  : datePeriod === 'WEEKLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('MMM YYYY')
                  : datePeriod === 'MONTHLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('MMM YYYY')
                  : datePeriod === 'YEARLY' ?
                    moment(dataTicketSystem?.label?.[dataTicketSystem?.label?.length-1]).format('YYYY')
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ borderTop: "2px dashed" }}></hr>
      <Col xxl="12">
        <h2 className="text-center" style={{ marginTop: 28, marginBottom: 28 }}>Total Sales per-PC vs Target</h2>
      </Col>
      <Col className="d-flex justify-content-between">
        {dataTotalSalesTarget.length > 0 ? 
        dataTotalSalesTarget.map((dst, i)=>{
          return (
            <Row className="mx-2 w-100 d-flex justify-content-center align-items-center">
              <TagChart
                title={dst?.name}
                value={
                  Number(
                    datePeriod === 'CUSTOM' ?
                      targetCards[i] ?? 0 /30
                    : datePeriod === 'DAILY' ?
                      targetCards[i] ?? 0 /30
                    : datePeriod === 'WEEKLY' ?
                      targetCards[i] ?? 0 /4
                    : datePeriod === 'YEARLY' ?
                      targetCards[i] ?? 0 *12
                    : targetCards[i] ?? 0 
                  ).toLocaleString().replaceAll(",", ".")
                }
                total={dst?.total.toLocaleString().replaceAll(",", ".")}
                chartConfiguration={
                  {
                    labels: ["", "", ""],
                    dataUnit: "BTC",
                    legend: false,
                    datasets: [
                      {
                        borderColor: "transparent",
                        backgroundColor: ["#0364FF", "#d9e5f7"],
                        data: [
                          datePeriod === 'CUSTOM' ?
                            Math.floor((dataTotalSalesTarget[i]?.total/(targetCards[i] ?? 0 / 30 )) * 100)
                          : datePeriod === 'DAILY' ?
                            Math.floor((dataTotalSalesTarget[i]?.total/(targetCards[i] ?? 0 / 30 )) * 100)
                          : datePeriod === 'WEEKLY' ?
                            Math.floor((dataTotalSalesTarget[i]?.total/(targetCards[i] ?? 0 / 4 )) * 100)
                          : datePeriod === 'YEARLY' ?
                            Math.floor((dataTotalSalesTarget[i]?.total/(targetCards[i] ?? 0 * 12 )) * 100)
                          : Math.floor((dataTotalSalesTarget[i]?.total/targetCards[i] ?? 0 ) * 100)
                          , 100],
                      },
                    ],
                    centerText: 
                      datePeriod === 'DAILY' ?
                        `${dataTotalSalesTarget && targetCards[i] && targetCards[i] !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCards[i]/30))*100) : 0}%`
                      : datePeriod === 'WEEKLY' ?
                        `${dataTotalSalesTarget && targetCards[i] && targetCards[i] !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCards[i]/4))*100) : 0}%`
                      : datePeriod === 'YEARLY' ?
                        `${dataTotalSalesTarget && targetCards[i] && targetCards[i] !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/(targetCards[i]*12))*100) : 0}%`
                      : `${dataTotalSalesTarget && targetCards[i] && targetCards[i] !== '0' ? Math.floor((dataTotalSalesTarget[0]?.total/targetCards[i])*100) : 0}%`  
                    ,
                    total: 100,
                  }
                }
              />
            </Row>)
        }) : <>No purchased data yet</>}
        {/* <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[1]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard2/30
                : datePeriod === 'WEEKLY' ?
                  targetCard2/4
                : datePeriod === 'YEARLY' ?
                  targetCard2*12
                : targetCard2
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[1]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#FFDA05", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2*12))*100)
                      : Math.floor((dataTotalSalesTarget[1]?.total/targetCard2)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/(targetCard2*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard2 && targetCard2 !== '0' ? Math.floor((dataTotalSalesTarget[1]?.total/targetCard2)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[2]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard3/30
                : datePeriod === 'WEEKLY' ?
                  targetCard3/4
                : datePeriod === 'YEARLY' ?
                  targetCard3*12
                : targetCard3
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[2]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#C49104", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3*12))*100)
                      : Math.floor((dataTotalSalesTarget[2]?.total/targetCard3)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/(targetCard3*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard3 && targetCard3 !== '0' ? Math.floor((dataTotalSalesTarget[2]?.total/targetCard3)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[3]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard4/30
                : datePeriod === 'WEEKLY' ?
                  targetCard4/4
                : datePeriod === 'YEARLY' ?
                  targetCard4*12
                : targetCard4
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[3]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#ADADAD", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4*12))*100)
                      : Math.floor((dataTotalSalesTarget[3]?.total/targetCard4)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/(targetCard4*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard4 && targetCard4 !== '0' ? Math.floor((dataTotalSalesTarget[3]?.total/targetCard4)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row>
        <Row className="mx-2">
          <TagChart
            title={dataTotalSalesTarget[4]?.name}
            value={
              Number(
                datePeriod === 'DAILY' ?
                  targetCard5/30
                : datePeriod === 'WEEKLY' ?
                  targetCard5/4
                : datePeriod === 'YEARLY' ?
                  targetCard5*12
                : targetCard5
              ).toLocaleString().replaceAll(",", ".")
            }
            total={dataTotalSalesTarget[4]?.total.toLocaleString().replaceAll(",", ".")}
            chartConfiguration={
              {
                labels: ["", "", ""],
                dataUnit: "BTC",
                legend: false,
                datasets: [
                  {
                    borderColor: "transparent",
                    backgroundColor: ["#2B9C51", "#d9e5f7"],
                    data: [
                      datePeriod === 'DAILY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/30))*100)
                      : datePeriod === 'WEEKLY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/4))*100)
                      : datePeriod === 'YEARLY' ?
                        Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5*12))*100)
                      : Math.floor((dataTotalSalesTarget[4]?.total/targetCard5)*100)
                      , 100],
                  },
                ],
                centerText: 
                  datePeriod === 'DAILY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/30))*100) : 0}%`
                  : datePeriod === 'WEEKLY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5/4))*100) : 0}%`
                  : datePeriod === 'YEARLY' ?
                    `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/(targetCard5*12))*100) : 0}%`
                  : `${dataTotalSalesTarget && targetCard5 && targetCard5 !== '0' ? Math.floor((dataTotalSalesTarget[4]?.total/targetCard5)*100) : 0}%`  
                ,
                total: 100,
              }
            }
          />
        </Row> */}
      </Col>
      </Card>
    </React.Fragment>
  );
};
export default TicketSystem;
