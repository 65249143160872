import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { serviceGetChatsPage, servicePutChat } from "services/chats";
import { serviceGetFilterMessage } from "services/messages";
import { socketIO } from "services/socket";
import { servicePutTickets } from "services/tickets";
import SimpleBar from "simplebar-react";
import { Button, Icon } from "../../../components/Component";
import { ListFilterMessage } from "./ChatFilterMessage";
import { ChatItemSide } from "./partials/ChatItemSide";
import { ChatContext } from "./ChatContext";

export const ChatAsideBody = ({
  cookies,
  onInputChange,
  setCheckedTag,
  isModalTagAssignOpen,
  setIsModalTagAssignOpen,
  afterCursorChat,
  setAfterCursorChat,
  filterTab,
  isSearch,
  afterCursorSearchedMessage,
  setAfterCursorSearchedMessage,
  searchText,
  isLoading,
  status,
  filterTag,
  setContextMessage,
  roleFunctions,
}) => {

  // Initiate Global State Context
  const {chatListState, chatListIdState, selectedChatState, selectedIdState , filteredMessageListState,
    filteredChatListState, queryChatFilterState, textFilterState, isLoadingSearchState } = useContext(ChatContext);
  const [isLoadingSearch, setIsLoadingSearch] = isLoadingSearchState;
  const [chatList, setChatList] = chatListState;
  const [chatIdList, setChatIdList] = chatListIdState;
  const [selectedChat, setSelectedChat] = selectedChatState;
  const [selectedId, setSelectedId] = selectedIdState;
  const [filteredChatList, setFilteredChatList] = filteredChatListState;
  const [filteredMessageList, setFilteredMessageList] = filteredMessageListState;
  const [queryChatFilter, setQueryChatFilter] = queryChatFilterState;
  const [textFilter, setTextFilter] = textFilterState;

  const [chatListSide, setChatListSide] = useState([]);
  
  const [ids, setIds] = useState([]);
  const [isLoadMoreChat, setLoadMoreChat] = useState(false);

  useEffect(() => {
    if(textFilter !== ""){
      console.log('menggunakan filter chat', textFilter, filteredChatList.length)
      setChatListSide(filteredChatList);
    }else{
      console.log('menggunakan chat biasa', textFilter, chatList.length)
      setChatListSide(chatList);
    }
  }, [filteredChatList, chatList, textFilter]);

  useEffect(() => {
    console.log('chatListSide',chatListSide)
  },[chatListSide])

  useEffect(() => {
    if (ids.length) {
      setChatIdList([...ids]);
    }
  }, [ids, setChatIdList]);

  const loadMoreChats = useCallback(async () => {
    // if (afterCursorChat.hasNextPage) {
    const responseGetChats = await serviceGetChatsPage(
      cookies.SIDikey,
      cookies.id,
      cookies.departmentId,
      status,
      filterTag.toString(),
      afterCursorChat?.page + 1,
      roleFunctions
    );

    const data = responseGetChats.data.data;

    const metaData = responseGetChats.data.meta;
    setAfterCursorChat(metaData);
    // sorting chat order by lastmessage timestamp
    if (data.length > 1) {
      data.sort((a, b) => b.lastMessage?.timestamp - a.lastMessage?.timestamp);
    }

    setChatList((chat) => [...chat, ...data]);
    setLoadMoreChat(false);
    // }
  }, [
    afterCursorChat?.page,
    cookies.SIDikey,
    cookies.departmentId,
    cookies.id,
    filterTag,
    roleFunctions,
    setAfterCursorChat,
    setChatList,
    status,
  ]);

  const loadMoreSearchedMessage = async (e) => {
    if (afterCursorSearchedMessage?.hasNextPage) {
      const responseGetMessages = await serviceGetFilterMessage(
        cookies.SIDikey,
        cookies.role.name,
        searchText,
        cookies.id,
        afterCursorSearchedMessage.page + 1
      );

      if (new RegExp(/20+/).test(responseGetMessages.status)) {
        const messageData = responseGetMessages.data.messages.data;
        const chatData = responseGetMessages.data.chats.data;
        setFilteredChatList((filteredChatList)=>[...filteredChatList, ...chatData]);
        setFilteredMessageList((filteredMessageList) => [...filteredMessageList, ...messageData]);
        setAfterCursorSearchedMessage(responseGetMessages.data.messages.meta);
      } else {
        console.log("Error get Messages from DB", responseGetMessages);
      }
    }
  };
  const handleClickChat = async (e, item) => {

    let currentStatusObj = {};
    let currentStatus = "";
    let currentTicketStatusObj = null;

    setSelectedId(item.id);

    // currentStatus = 'UNOPENED' ? "OPENED" : item.status;
    switch(cookies.role.name){
      case "SALES":
        currentStatus = item.status;
        currentStatusObj = {status: item.status === 'UNOPENED' ? "OPENED" : item.status, unreadCount: 0}
        if(item.userId === cookies.id){ // jika chat milik dirinya
          currentTicketStatusObj = {status: item.status === 'UNOPENED' ? "OPENED" : item.status}
        }
        break
      case "CS":
        currentStatus = item.csStatus;
        currentStatusObj = {csStatus: item.csStatus === 'UNOPENED' ? "OPENED" : item.csStatus, unreadCountCs: 0}
        if(item.userId === cookies.id){ // jika chat milik dirinya
          currentTicketStatusObj = {status: item.csStatus === 'UNOPENED' ? "OPENED" : item.csStatus}
        }
        break
      case "SUPER ADMIN":
        currentStatus = item.superStatus;
        currentStatusObj = {superStatus: item.superStatus === 'UNOPENED' ? "OPENED" : item.superStatus, unreadCountSuper: 0}
        break
      case "HEAD":
        currentStatus = item.headStatus;
        currentStatusObj = {headStatus: item.headStatus === 'UNOPENED' ? "OPENED" : item.headStatus, unreadCountHead: 0}
        break
      case "AUDITOR":
          currentStatus = item.auditorStatus;
          currentStatusObj = {auditorStatus: item.auditorStatus === 'UNOPENED' ? "OPENED" : item.auditorStatus, unreadCountAuditor: 0}
          break
        case "SYSTEM ADMIN":
          currentStatus = item.sysAdminStatus;
          currentStatusObj = {sysAdminStatus: item.sysAdminStatus === 'UNOPENED' ? "OPENED" : item.sysAdminStatus, unreadCountSysAdmin: 0}
          item.sysAdminStatus = currentStatusObj.sysAdminStatus;
          break
      default:
        currentStatus = item.adminStatus;
        currentStatusObj = {adminStatus: item.adminStatus === 'UNOPENED' ? "OPENED" : item.adminStatus, unreadCountAdmin: 0}
        break
    }
    console.log('handleClickChat', item, currentTicketStatusObj)

    
    if (!roleFunctions.includes('View_Chatbot')) {
      await servicePutChat(cookies.SIDikey, item.id, { ...currentStatusObj });
      // if (currentStatus === "UNOPENED") {
      // }
      
      if(currentTicketStatusObj){ // jika ini dari cs atau sales, ticket harus di update
      // if(currentTicketStatusObj && currentTicketStatusObj.status !== item.tickets[0].status){ // jika ini dari cs atau sales, ticket harus di update
        console.log('iya ini dirubah');
       
        if(item.tickets && item.tickets.length > 0){
          await servicePutTickets(item.tickets[0].id, cookies.SIDikey, { ...currentTicketStatusObj });
          item.tickets[0].status = currentTicketStatusObj.status;
        }
        const updateTicketStatus = {
          type: "status-update",
          id: item.id,
          ticketId: item.tickets ? item.tickets[0].id : item.lastMessage.ticketId,
          chat: {
            ...currentStatusObj,
            id: item.id,
            user: item.user,
            channel: item.channel
          },
          chatId: item.id,
          fromMe: true,
          ...currentTicketStatusObj
        };
        socketIO.emit("admin", JSON.stringify(updateTicketStatus));
        
        const localChats = [...chatList];
        const fIndex = localChats.findIndex(el => el.id === item.id);
    
        if (fIndex !== -1) {
          localChats[fIndex] = { ...localChats[fIndex], ...currentStatusObj };
        }
        console.log(localChats[fIndex])
        setChatList([...localChats]);
      }
    } else {
      if (currentStatus === "UNOPENED") {
        await servicePutChat(cookies.SIDikey, item.id, {...currentStatusObj});
      }
    }

  };

  const scrollRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current?.scrollTop + scrollRef.current?.clientHeight >= scrollRef.current?.scrollHeight - 1) {
        if (!isLoadMoreChat) {
          setLoadMoreChat(true);
          loadMoreChats();
        }
        //isLoadMoreChat === false && loadMoreChats();
      }
    };

    scrollRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadMoreChat, loadMoreChats]);

  

  return (
    <SimpleBar className="nk-chat-aside-body" id="chat-list" scrollableNodeProps={{ ref: scrollRef }}>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">{filterTab}</h6>

        <ul className="chat-list">
          {isLoading ? (
            <div className="d-flex justify-content-center align-item-strecth">
              <Spinner animation="border" role="status" color="blue" />
            </div>
          ) : chatListSide.length !== 0 ? (
            chatListSide.map((item, idx) => {
              let currentItemStatus = '';
              switch(cookies.role.name){
                case "SALES":
                  currentItemStatus = item.status;
                  break
                case "CS":
                  currentItemStatus = item.csStatus;
                  break
                case "SUPER ADMIN":
                  currentItemStatus = item.superStatus;
                  break
                case "HEAD":
                  currentItemStatus = item.headStatus;
                  break
                case "ADMIN":
                  currentItemStatus = item.adminStatus;
                  break
                case "AUDITOR":
                  currentItemStatus = item.auditorStatus;
                  break
                case "SYSTEM ADMIN":
                  currentItemStatus = item.sysAdminStatus;
                  break
                default:
                  currentItemStatus = item.adminStatus;
                  break
              }
              
              return (
                !item.group && (
                  <div key={idx} className="d-flex" onClick={(e) => handleClickChat(e, item)}>
                    {isModalTagAssignOpen && ( <></>
                      // <input
                      //   type="checkbox"
                      //   onChange={(e) => handleChangeInput(e, item)}
                      //   style={{ marginRight: "8px" }}
                      //   checked={ids.some((item2) => item2 === item.id)}
                      // />
                    )}
                    <ChatItemSide
                      item={item}
                      setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                      setIds={setIds}
                      currentStatus={currentItemStatus}
                    />
                  </div>
                )
              );
            })
          ) : (
            <p className="m-3"></p>
          )}
          <>
            {afterCursorChat?.hasNextPage && afterCursorChat?.hasNextPage && !isSearch ? (
              <div className="d-flex justify-content-center mt-2 mb-3">
                <Button outline color="light" size="sm">
                  {isLoadMoreChat ? (
                    <Spinner></Spinner>
                  ) : (
                    <div className="mb-1 mt-1">
                      <span>Load More Chats</span>
                      <Icon name="reload-alt" />
                    </div>
                  )}
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        </ul>

        <ListFilterMessage
          cookies={cookies}
          isSearch={isSearch}
          result={filteredMessageList}
          searchText={searchText}
          setContextMessage={setContextMessage}
        />
        {afterCursorSearchedMessage?.hasNextPage && isSearch ? (
          <div className="d-flex justify-content-center mb-2">
            <Button outline color="light" size="sm" onClick={(e) => loadMoreSearchedMessage(e)}>
              <span>Load More Message</span>
              <Icon name="reload-alt" />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </SimpleBar>
  );
};
