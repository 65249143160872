export const DefaultLeadFilters = [
    {
      text: 'Status',
      value: 'leadStatus',
      icon: 'disk',
      isSelected: false,
      type: "checkbox",
      keyOptions: "ticket.status",
      query: "ticket.status",
      options: ['RESOLVED', 'OPENED', 'UNOPENED', 'PENDING']
    },
    {
      text: 'Sales',
      value: 'sales',
      icon: 'coins',
      isSelected: false,
      type: "checkbox",
      keyOptions: "sales",
      query: "ticket.salesId",
    },
    // {
    //   text: 'Address',
    //   value: 'address',
    //   icon: 'location',
    //   isSelected: false,
    //   type: "text",
    //   keyOptions: "contact",
    //   query: "chat.contact.address",
    // },
    // {
    //   text: 'City',
    //   value: 'city',
    //   icon: 'building',
    //   isSelected: false,
    //   type: "text",
    //   keyOptions: "contact",
    //   query: "chat.contact.district",
    // },
    // {
    //   text: 'Email',
    //   value: 'email',
    //   icon: 'emails',
    //   isSelected: false,
    //   type: "text",
    //   keyOptions: "contact",
    //   query: "chat.contact.email",
    // },
    // {
    //   text: 'Phone',
    //   value: 'customerPhone',
    //   icon: 'call',
    //   isSelected: false,
    //   type: "text",
    //   keyOptions: "chats",
    //   query: "chat.customerPhone",
    // },
    // {
    //   text: 'Product',
    //   value: 'products',
    //   icon: 'briefcase',
    //   isSelected: false,
    //   type: "checkbox",
    //   keyOptions: "contact"
    // },
    // {
    //   text: 'Lead',
    //   value: 'name',
    //   icon: 'user',
    //   isSelected: false,
    //   type: "checkbox",
    //   keyOptions: "contact"
    // },
  ]

export const DefaultKeyLeadsFilter = {
  Status:true,
  Address:false,
  City:false,
  Sales:false,
  Email:false,
  Phone:false,
  Product:true,
  Lead:true,
  Media:false
}