import { MessagesContext } from "contexts/messagesContext";
import { ReplyContext } from "contexts/replyContext";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { Input, Nav, NavItem, NavLink } from "reactstrap";
import { serviceGetChatById, serviceGetChats, servicePutChat } from "services/chats";
import { getDepartment, getDepartmentByID } from "services/departments";
import { serviceGetFilterMessage } from "services/messages";
import { serviceGetTicketById, servicePutTickets } from "services/tickets";
import SimpleBar from "simplebar-react";
import { useDebounce } from "use-debounce";
import { Icon } from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import { ChatAsideBody } from "./ChatAsideBody";
import ChatBody from "./ChatBody";
import { ChatContext } from "./ChatContext";
import ChatModalFilter from "./ChatModalFilter";

import { socketIO } from "services/socket";
import { serviceGetTicketsById } from "services/tickets";
import lonceng4 from "../../../assets/sound/lonceng4.mp3";
import { addChatWithoutDuplication, chatNoDuplicate, combine2ChatNoDuplicate } from "./utils/ChatUtils";
import { serviceGetUsersByRoleId } from "services/users";

const Chat = () => {

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  // Initiate Global State Context
  const {chatListState, chatListIdState, selectedChatState, selectedIdState, filteredMessageListState,
    filteredChatListState, queryChatFilterState , textFilterState, isLoadingSearchState, salesListState} = useContext(ChatContext);
  
  const [isLoadingSearch, setIsLoadingSearch] = isLoadingSearchState;
  const [chatList, setChatList] = chatListState;
  const [chatIdList, setChatIdList] = chatListIdState;
  const [selectedChat, setSelectedChat] = selectedChatState;
  const [filteredChatList, setFilteredChatList] = filteredChatListState;
  const [queryChatFilter, setQueryChatFilter] = queryChatFilterState;
  const [filteredMessageList, setFilteredMessageList] = filteredMessageListState;
  const [selectedId, setSelectedId] = selectedIdState;
  const [textFilter, setTextFilter] = textFilterState;
  const [salesList, setSalesList] = salesListState;
  // Initiate Global State Context
  
  const [filterTab, setFilterTab] = useState("all");
  // const [filteredChatListLocal, setFilteredChatListLocal] = useState([]);
  // const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [afterCursorSearchedMessage, setAfterCursorSearchedMessage] = useState({});
  const [filterTag, setFilterTag] = useState([]);
  const [searchText] = useDebounce(textFilter, 500);
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [isModalTagAssignOpen, setIsModalTagAssignOpen] = useState(false);
  const [checkedTag, setCheckedTag] = useState([]);
  const {messagesValue, statusValue, salesUpdate } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;
  const [cookies] = useCookies(["user"]);
  const [isModalTagFilterOpen, setIsModalTagFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [replyMessage, setReplyMessage] = useState(null);
  const [contextMessage, setContextMessage] = useState(null);
  const [afterCursorChat, setAfterCursorChat] = useState(null);
  const [assignMessage, setAssignMessage] = useState();

  const { search } = useLocation();

  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["chats"].access || [];

  const ALL_DEPT = "Access_All_Dept";
  const OWN_DEPT = "Access_Own_Dept";
  const OWN = "Access_Own";
  const BOT_ID = "3ef9f486-ddad-46ed-b568-f7b32a62262f";

  const refreshChatList = async () => {
    const status = activeTab !== "all" ? activeTab.toUpperCase() : "";

    setIsLoading(true);

    const responseGetChats = await serviceGetChats(
      cookies.SIDikey,
      cookies.id,
      cookies.departmentIds,
      status,
      filterTag.toString(),
      roleFunctions,
      cookies.role.name
    );
    if (new RegExp(/20+/).test(responseGetChats.status)) {
      let data = responseGetChats.data.data;

      const metaData = responseGetChats.data.meta;
      setAfterCursorChat(metaData);
      // sorting chat order by lastmessage timestamp
      // try {
      //   if (data.length > 1) {
      //     // sorting
      //     data.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
      //     // filtering chatData
      //     // data = data.filter((item)=>{return item.status !== 'RESOLVED'});
      //   }
      // } catch (error) {
      //   // console.log("unsort", error);
      // }

      const noDuplicateChat = chatNoDuplicate(data);
      
      setChatList(noDuplicateChat);
      setChatIdList(
        data.map((item) => {
          return item.id;
        })
      );
    } else {
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isModalTagAssignOpen) {
      console.log('ke regresh');
      refreshChatList();
    }
  }, [ activeTab, filterTag]);

  // useEffect(() => {
  //   //Simpan data contact ke dalam context isSaved
  //   let contactIds = chat.map(item => item.contactId)

  //   if(contactIds.length > 0) {
  //     fetchContacts(contactIds);
  //   }
  // }, [chat]);

  useEffect(() => {
    //Simpan data contact ke dalam context isSaved
    // let contactIds = chatList.map((item) => item.contactId);

    // if (contactIds.length > 0) {
    //   //fetchContacts(contactIds);
    // }
  }, []);

  const refreshUserList = async () => {
      let responseGetSales = null;
      let responseGetCS = null;
      const salesID = "SALES,CS";
  
      // segregation of duty implementation
      if (roleFunctions.includes("Access_All_Dept")) {
        responseGetSales = await serviceGetUsersByRoleId(cookies.SIDikey, "", salesID);
        // responseGetCS = await serviceGetUsersByRoleId(cookies.SIDikey, "", csID);
      } else {
        responseGetSales = await serviceGetUsersByRoleId(cookies.SIDikey, cookies.departmentIds, salesID);
        // responseGetCS = await serviceGetUsersByRoleId(cookies.SIDikey, cookies.departmentIds, csID);
      }
  
      if (!responseGetSales) {
        return;
      }
  
      if (
        (responseGetSales && new RegExp(/20+/).test(responseGetSales?.status)) ||
        (responseGetCS && new RegExp(/20+/).test(responseGetCS?.status))
      ) {
        setSalesList(responseGetSales.data.data)
        // setUserList(data.filter((item) => item.isActive === true).sort((a, b) => b.status.localeCompare(a.status)));
      }
    };

  // if the opened chat have new message
  const playSound = () => {
    try {
      const sound = new Audio(lonceng4);
      sound.play();
    } catch (error) {
      console.log(error);
    }
  };

  const refreshChatDataOnNewMessage = (chatData, newMessage) => {
    // Early return for bot messages if user can't view chatbot
    if (!roleFunctions.includes('View_Chatbot') && newMessage.chat?.userId === BOT_ID) {
      if (selectedId === newMessage.chat?.id) setSelectedId(null);
      return { newChats: [...chatData], found: false, foundIndex: -1 };
    }
  
    const foundIndex = chatData.findIndex(chat => newMessage?.chat?.id === chat.id);
    const found = foundIndex !== -1;
  
    // If message doesn't match any chat, return original data
    if (!found) return { newChats: [...chatData], found, foundIndex };
  
    // Create a copy of the chat data array
    const newChats = [...chatData];
    
    // Get the chat that needs to be updated
    let updatedChat = { ...newChats[foundIndex] };
    
    // Update user information if available
    if (newMessage.chat?.user) {
      updatedChat.user = newMessage.chat.user;
      updatedChat.userId = newMessage.chat.userId;
    }
    
    // Update tickets if present
    if (newMessage?.tickets) {
      updatedChat.tickets = newMessage.tickets;
    }
    
    // Update tags if present
    if (newMessage?.tags || newMessage?.chat?.tags) {
      updatedChat.tags = newMessage?.chat?.tags || newMessage?.tags;
    }
    
    // Update last message for regular messages
    if (newMessage.type !== "tag-update" && newMessage.type !== "tag-update-resolve") {
      updatedChat.lastMessage = newMessage;
      
      // Play sound for incoming user messages
      if (!newMessage.fromMe) {
        try {
          playSound();
        } catch (error) {
          console.log(error);
        }
      }
    }
    
    // Update unread counts
    const unreadCountFields = [
      'unreadCount', 'unreadCountAdmin', 'unreadCountCs', 
      'unreadCountHead', 'unreadCountSuper', 'unreadCountAuditor', 
      'unreadCountSysAdmin'
    ];
    
    unreadCountFields.forEach(field => {
      if (newMessage.chat?.[field] !== undefined) {
        updatedChat[field] = newMessage.chat[field];
      }
    });
    
    // Update status fields
    const statusFields = [
      'status', 'adminStatus', 'csStatus', 
      'headStatus', 'superStatus', 'auditorStatus', 
      'sysAdminStatus'
    ];
    
    statusFields.forEach(field => {
      if (newMessage.chat?.[field]) {
        updatedChat[field] = newMessage.chat[field];
      }
    });
    
    // Handle selected chat updates
    if (selectedId === newMessage.chatId) {
      const { statusObj, ticketStatusObj } = determineStatusUpdates(updatedChat, cookies);
      
      updatedChat = applyStatusUpdates(updatedChat, statusObj);
      
      // Update chat and ticket statuses if needed
      if (!roleFunctions.includes('View_Chatbot')) {
        if (ticketStatusObj) {
          servicePutChat(cookies.SIDikey, updatedChat.id, { ...statusObj });
          
          if (updatedChat.tickets && updatedChat.tickets.length > 0) {
            updateTicketStatusAndEmit(updatedChat, cookies, ticketStatusObj, statusObj, socketIO);
          }
        }
      } else if (statusObj.currentStatus === "UNOPENED") {
        servicePutChat(cookies.SIDikey, updatedChat.id, { ...statusObj });
      }
    }
    
    // Remove the chat from its original position
    newChats.splice(foundIndex, 1);
    
    // Add the updated chat to the beginning of the array
    return { newChats: [updatedChat, ...newChats], found, foundIndex };
  };
  
  // Helper function to determine status updates based on user role
  const determineStatusUpdates = (chat, cookies) => {
    const roleMap = {
      "SALES": {
        currentStatus: chat.status,
        statusField: 'status',
        unreadField: 'unreadCount',
        condition: chat.userId === cookies.id
      },
      "CS": {
        currentStatus: chat.csStatus,
        statusField: 'csStatus',
        unreadField: 'unreadCountCs',
        condition: chat.userId === cookies.id
      },
      "SUPER ADMIN": {
        currentStatus: chat.superStatus,
        statusField: 'superStatus',
        unreadField: 'unreadCountSuper',
        condition: false
      },
      "HEAD": {
        currentStatus: chat.headStatus,
        statusField: 'headStatus',
        unreadField: 'unreadCountHead',
        condition: false
      },
      "AUDITOR": {
        currentStatus: chat.auditorStatus,
        statusField: 'auditorStatus',
        unreadField: 'unreadCountAuditor',
        condition: false
      },
      "SYSTEM ADMIN": {
        currentStatus: chat.sysAdminStatus,
        statusField: 'sysAdminStatus',
        unreadField: 'unreadCountSysAdmin',
        condition: false
      },
      "default": {
        currentStatus: chat.adminStatus,
        statusField: 'adminStatus',
        unreadField: 'unreadCountAdmin',
        condition: false
      }
    };
    
    const role = roleMap[cookies.role.name] || roleMap.default;
    const currentStatus = role.currentStatus;
    const newStatus = currentStatus === 'UNOPENED' ? 'OPENED' : currentStatus;
    
    const statusObj = { [role.statusField]: newStatus, [role.unreadField]: 0, currentStatus };
    
    const ticketStatusObj = role.condition ? { status: newStatus } : null;
    
    return { statusObj, ticketStatusObj };
  };
  
  // Helper function to apply status updates to a chat
  const applyStatusUpdates = (chat, statusObj) => {
    const updatedChat = { ...chat };
    
    switch(cookies.role.name) {
      case "SALES":
        updatedChat.status = statusObj.status;
        updatedChat.unreadCount = 0;
        break;
      case "CS":
        updatedChat.csStatus = statusObj.csStatus;
        updatedChat.unreadCount = 0;
        break;
      case "SUPER ADMIN":
        updatedChat.superStatus = statusObj.superStatus;
        break;
      case "HEAD":
        updatedChat.headStatus = statusObj.headStatus;
        break;
      case "AUDITOR":
        updatedChat.auditorStatus = statusObj.auditorStatus;
        break;
      case "SYSTEM ADMIN":
        updatedChat.sysAdminStatus = statusObj.sysAdminStatus;
        break;
      default:
        updatedChat.adminStatus = statusObj.adminStatus;
        break;
    }
    
    return updatedChat;
  };

  const updateTicketStatusAndEmit = async (ch, cookies, currentTicketStatusObj, currentStatusObj, socketIO) => {
    if (ch.tickets && ch.tickets.length > 0) {
      try {
        const response = await servicePutTickets(ch.tickets[0].id, cookies.SIDikey, { ...currentTicketStatusObj });
        
        if (response && response.status >= 200 && response.status < 300) {
          // Assuming status codes in the 200 range indicate success
          ch.tickets[0].status = currentTicketStatusObj.status;
          
          const updateTicketStatus = {
            type: "status-update",
            id: ch.id,
            ticketId: ch.tickets ? ch.tickets[0].id : ch.lastMessage.ticketId,
            chat: {
              ...currentStatusObj,
              id: ch.id,
              user: ch.user,
              channel: ch.channel
            },
            chatId: ch.id,
            fromMe: true,
            ...currentTicketStatusObj
          };
          
          socketIO.emit("admin", JSON.stringify(updateTicketStatus));
        } else {
          console.error('Failed to update ticket:', response);
        }
      } catch (error) {
        console.error('Error while updating ticket:', error);
      }
    }
  };

  useEffect(() => {
    if (!salesUpdate[0]) {
      return;
    }
    // console.log('ya terassign : ',salesUpdate);
    assignChatFromRotator(salesUpdate);
  }, [newSales]);

  const assignChatFromRotator = async (emitData) => {
    if (!Array.isArray(emitData)) {
      console.error('Invalid emitData: Expected an array');
      return;
    }
  
    let newChatSales = [];
    const updatedChat = [...chatList]; // Clone current chat list to avoid direct mutations
  
    for (const salesArr of emitData) {
      if (!Array.isArray(salesArr)) {
        console.warn('Invalid salesArr: Expected an array');
        continue;
      }
  
      for (const sales of salesArr) {
        if (!sales || !sales.chat || !sales.chatId || !sales.ticket) {
          console.warn('Invalid sales object: Missing necessary properties');
          continue;
        }

        console.log({
          chatUser: sales.chat.user.email, chatName: sales.chat.name, dept: sales.department.name
        })
  
        const newChat = sales.chat;
        if (selectedId === newChat.id) {
          setSelectedId(null);
        }
  
        newChat.tickets = sales.ticket;
        newChat.lastMessage = sales.chat.messages[0];
        if (newChat.channel && sales.department) {
          newChat.channel.department = sales.department;
        }
  
        const targetChatIndex = updatedChat.findIndex((item) => item.id === sales.chatId);
  
        if (roleFunctions.includes(OWN)) { // If user has own/sales role
          if (sales?.chat?.user?.id === cookies.id) { // CHAT BUAT SALES INI
            await handleNewChatAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales);
          } else { // If the chat is for the current user
            console.log('anda bukan user', targetChatIndex, sales?.chat?.user?.id, cookies.id, sales?.chat?.user?.id === cookies.id);
            if (targetChatIndex >= 0) {
              updatedChat.splice(targetChatIndex, 1);
              setSelectedId(null);
            }
          }
        } else { // If user is not a sales representative
          // updatedChat[targetChatIndex] = newChat
          // updatedChat[targetChatIndex].tickets = sales.ticket;
          await handleNonSalesAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales);
        }
      }
    }

    const noDuplicateChat = combine2ChatNoDuplicate(newChatSales, updatedChat);
      
    setChatList(noDuplicateChat);
  
  };
  
  async function handleNewChatAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales) {
    try {
      playSound();
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  
    if (targetChatIndex >= 0) {
      updatedChat[targetChatIndex] = newChat;
      updatedChat[targetChatIndex].tickets = sales.ticket;
      
      if (selectedId === updatedChat[targetChatIndex].id) {
        setSelectedId(null);
      }
    } else if(updatedChat.length === 0 || targetChatIndex < 0) { // jika chat tidak ditemukan atau tidak memiliki chat sebelumnya
      newChatSales.push(newChat);
      if (selectedId === newChat.id) {
        setSelectedId(null);
      }
    }
  }

  async function handleNonSalesAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales) {
    
    try {
      playSound();
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  
    if (targetChatIndex >= 0) {
      
      updatedChat[targetChatIndex] = newChat;
      updatedChat[targetChatIndex].tickets = sales.ticket;

    } else if(updatedChat.length === 0 || targetChatIndex < 0) { // jika chat tidak ditemukan atau tidak memiliki chat sebelumnya
      
      newChatSales.push(newChat);

    }
  }
  
  // async function handleNonSalesAssignment(sales, updatedChat, targetChatIndex) {
  //   if (targetChatIndex >= 0) {
  //     const currentChat = updatedChat[targetChatIndex];
  //     if (selectedId === currentChat.id) {
  //       setSelectedId(null);
  //     }
  
  //     if (sales.userId !== currentChat.userId) {
  //       sales.user.department = sales.department;
  //       currentChat.user = sales.user;
  //       currentChat.userId = sales.userId;
  //     }
  //   }
  // }

  useEffect(() => {

    console.log('ada new messages')

    //Jika ada pesan baru jika ada emit baru
    //unread refresh algorithm
    let newMessage = newMessages.at(-1);
    if (newMessage === undefined) {
      return;
    }else if(newMessage.type === "status-update") {

      let currentChats = [...filteredChatList];
      
      // Step 2: Find the index of the item to be updated
      let indexToUpdate = currentChats.findIndex(cht => cht.id === newMessage.chatId);
      
      // Step 3: Check if the item exists in the list
      if (indexToUpdate !== -1) {
        // Step 4: Create a new list with the updated item
          let updatedChat = {
            ...currentChats[indexToUpdate], ...newMessage.chat
            // Add any properties from newMessage or other updates needed
          };
  
          // Step 5: Update the item in the list
          currentChats[indexToUpdate] = updatedChat;
          const noDuplicateChat = chatNoDuplicate(currentChats);
          setChatList(noDuplicateChat);
      }

      // Step 6: Set the updated currentChats list in setChat
      return;
    }

    // ASSIGN
    // jika kamu adalah SALES atau OWN USER
    if(roleFunctions.includes(OWN)){
      if(newMessage?.chat?.userId && newMessage?.chat?.userId !== cookies.id ){
        try {
          removeChatById(newMessage?.chat?.id)
        } catch (error) {}
        return;
      }
    }
    
    // Jika bukan access all dept
    // jika emit bukan chat department miliknya
    if(!roleFunctions.includes(ALL_DEPT)){
      // jika itu bukan depts nya
      if(newMessage?.chat?.channel?.departmentId && !cookies.departmentIds.includes(newMessage?.chat?.channel?.departmentId)){
        console.log('Kamu bukan di dept ini');
        removeChatById(newMessage?.chat?.id);       
        return;
        //jika chat adalah bot session
      }
    }

    // SALES dan CS tidak menerima chatbot
    if(!roleFunctions.includes('View_Chatbot')){
      if(newMessage?.chat?.fromBot || newMessage?.chat?.userId === BOT_ID){
        removeChatById(newMessage?.chat?.id);      
        return;
      }
    }

    if (chatList.length === 0) {
      addNewChatToChats(newMessage);
      return;
    }

    let addNewMessage = null;
    let newChats = null;
    let found = null;

    //jika ada filter chat di sebelumnya
    if (searchText) {
      addNewMessage = refreshChatDataOnNewMessage(filteredChatList, newMessage);
      newChats = addNewMessage.newChats;
      found = addNewMessage.found;
      if (found) {
        setFilteredChatList(newChats);
      }
    }else{
      addNewMessage = refreshChatDataOnNewMessage(chatList, newMessage);
      console.log('addNewMessage', addNewMessage);
      newChats = addNewMessage.newChats;
      found = addNewMessage.found;
    }


    if (found) {
      const targetChat = newChats.find((itm) => itm.id === newMessage.chatId)
      if (newMessage.ticketId && targetChat.tickets) {
        if (targetChat.tickets[0].id !== newMessage.ticketId) {
          addNewTicketToChat(newChats, newMessage.ticketId, targetChat);
        } else {
          const noDuplicateChat = chatNoDuplicate(newChats);
          setChatList(noDuplicateChat);
        }
      } else {
        addNewTicketToChat(newChats, newMessage.ticketId, targetChat);
      }
    } else {
      if(chatList && chatList.length > 0){
        addNewChatToChats(newMessage);
      }else{
        refreshChatList();
      }
    }
    newMessages.pop();
  }, [newMessages]);



  const removeChatById = async (chatId) => {

    if(!chatId) return;

    // remove chat yang ada dalam kuasa bot
    let localChat = [...chatList];
    console.log('remove chat by id');
    localChat = localChat.filter((c)=> c.id !== chatId)
    const noDuplicateChat = chatNoDuplicate(localChat);
    setChatList(noDuplicateChat);
    
    if(selectedId === chatId){
      setSelectedId(null);
    }
  };

  const addNewTicketToChat = async (newChats, newTicketId, targetChat) => {
    if (!newTicketId) return;
    const resNetTicket = await serviceGetTicketsById(cookies.SIDikey, newTicketId);
    const findTargetChatIndex = newChats.findIndex((itm) => itm.id === targetChat.id)
    newChats[findTargetChatIndex].tickets = resNetTicket.data.data;
    newChats[findTargetChatIndex].ticketId = resNetTicket.data.data[0].id;
    const noDuplicateChat = chatNoDuplicate(newChats);
    setChatList(noDuplicateChat);
  };

  const addNewChatToChats = async (newMessage) => {
    if (newMessage?.chat) {
      // emit coming from chat
      const isHaveTargetChat = chatList.findIndex((ch) => ch.id === newMessage.chat.id);
      const newChat = newMessage.chat;
      newChat.lastMessage = newMessage;
      if(!newChat.tags) {
        const resNewChat = await serviceGetChatById(cookies.SIDikey, newChat.id);
        if (resNewChat.status === 200) {
          newChat.tags = resNewChat.data.tags
        } else {
          newChat.tags = []
        }
      }
      if (!newChat.channel && newChat.tickets) {
        const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
        if (resDeptName.status === 200) {
          newChat.channel = {
            department: {
                name:resDeptName.data.name,
                color:resDeptName.data.color
            }
          };
        }
      }

      if (newMessage.chat.user && newMessage.chat.user.id !== cookies.id) {
        // chat pindah sales lain
        if (cookies.role.name === "SALES") {
          if (isHaveTargetChat >= 0) {
            //const newChat = [...chat];
            chatList.splice(isHaveTargetChat, 1);
            //setChat([...newChat]);
          }
        } else {
          if (isHaveTargetChat < 0) {
            const resNewChat = await getMissingChatData(newChat, newMessage);
            const noDuplicateChat = addChatWithoutDuplication(resNewChat, chatList);
            setChatList(noDuplicateChat);
          }
        }
      } else {
        if (isHaveTargetChat < 0) {
          const resNewChat = await getMissingChatData(newChat, newMessage);
          const noDuplicateChat = addChatWithoutDuplication(resNewChat,chatList);
          setChatList(noDuplicateChat);
        }
      }
    } else {
      // emit coming from rotator
      if (newMessage?.chat?.user?.id ?? newMessage.userId === cookies.id) {
        const resNewChat = await serviceGetChatById(cookies.SIDikey, newMessage.chatId);
        if (resNewChat.status === 200) {
          const resDeptName = await getDepartment(cookies.SIDikey, resNewChat.data.user.departmentId);
          if (resDeptName.status === 200) {
            const getCurrentDept = resDeptName.data.data.find((item) => item.id === resNewChat.data.user.departmentId);
            const newChat = resNewChat.data;
            const getTicketBychatId = await serviceGetTicketsById(cookies.SIDikey, newMessage.ticket[0].id);
            if (getTicketBychatId.status === 200) {
              newChat.tickets = getTicketBychatId.data.data;
              newChat.user.department = getCurrentDept;
              newChat.lastMessage.ticketId = newMessage.ticket[0].id;
              newChat.channel.department = newMessage.department

              const noDuplicateChat = combine2ChatNoDuplicate(newChat,chatList);
              setChatList(noDuplicateChat);
            }
          }
        }
      }
    }
    playSound();
  };

  const getMissingChatData = async (newChat, newMessage) => { // multi department bug
    const newChatData = newChat
    if (!newChat.tickets) {
      const resGetTickets = await serviceGetTicketById(cookies.SIDikey, newMessage.ticketId);
      if (resGetTickets.status === 200) {
        newChatData.tickets = [resGetTickets.data];
      }
    }
    if (!newChat.contactId) {
      const resGetChat = await serviceGetChatById(cookies.SIDikey, newMessage.chat.id);
      if (resGetChat.status === 200) {
        newChatData.contactId = resGetChat.contactId;
      }
    }
    //if (!newChat.user.department) {
    //  const resDeptName = await getDepartment(cookies.SIDikey, newChat.user.departmentId);
    //  if (resDeptName.status === 200) {
    //    const getCurrentDept = resDeptName.data.data.find((item) => item.id === newChat.user.departmentId);
    //    newChat.user.department = getCurrentDept;
    //  }
    //}
    if (!newChat.channel && newChat.tickets) {
      const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
      if (resDeptName.status === 200) {
        newChatData.channel = {
          department: {
              name:resDeptName.data.name,
              color:resDeptName.data.color
          }
        };
      }
    }
    return newChat
    //setChat([newChat, ...chat]);
  };
  // Filtering users by search
  useEffect(() => {
    if (textFilter !== "") {
      const filteredObject = chatList.filter((item) => {
        return item.name.toLowerCase().includes(textFilter.toLowerCase());
      });
      filterMessage(filteredObject);
    } else {
      setFilteredChatList([]);
      setFilteredMessageList([]);
      setAfterCursorSearchedMessage(null);
    }
  }, [searchText]);

  useEffect(() => {
    let newChats = [];
    chatList.forEach((ch) => {
      if (ch.id === selectedId) {
        switch(cookies.role.name){
          case "SALES":
            ch.status = ch.status === 'UNOPENED' ? "OPENED" : ch.status;
            ch.unreadCount = 0;
            break
          case "CS":
            ch.csStatus = ch.csStatus === 'UNOPENED' ? "OPENED" : ch.csStatus;
            ch.unreadCountCs = 0;
            break
          case "SUPER ADMIN":
            ch.superStatus = ch.superStatus === 'UNOPENED' ? "OPENED" : ch.superStatus;
            ch.unreadCountSuper = 0;
            break
          case "HEAD":
            ch.headStatus = ch.headStatus === 'UNOPENED' ? "OPENED" : ch.headStatus;
            ch.unreadCountHead = 0;
            break
          case "ADMIN":
            ch.adminStatus = ch.adminStatus === 'UNOPENED' ? "OPENED" : ch.adminStatus;
            ch.unreadCountAdmin = 0;
            break
          case "AUDITOR":
            ch.auditorStatus = ch.auditorStatus === 'UNOPENED' ? "OPENED" : ch.auditorStatus;
            ch.unreadCountAuditor = 0;
            break
          case "SYSTEM ADMIN":
            ch.auditorSysAdmin = ch.auditorSysAdmin === 'UNOPENED' ? "OPENED" : ch.auditorSysAdmin;
            ch.unreadCountSysAdmin = 0;
            break
          default:
            break
        }
      }
      newChats.push(ch);
    });
    const selectedChatTemp = newChats.find((item) => item.id === selectedId);
    setSelectedChat(selectedChatTemp);
    setChatList([...newChats]);
  }, [selectedId]);

  const filterMessage = async (filteredObject) => {

    setIsLoading(true);
    const responseGetMessages = await serviceGetFilterMessage(
      cookies.SIDikey,
      cookies.role.name,
      textFilter,
      cookies.id
    );
    if (new RegExp(/20+/).test(responseGetMessages.status)) {
      const messageData = responseGetMessages.data.messages.data;
      const chatData = responseGetMessages.data.chats.data;
      chatData.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
      // const filteredChatData = chatData.filter((item) => {
      //   return !chatIdList.includes(item.id);
      // });
      // setChatList((chat) => [...chat, ...filteredChatData]);
      // setChatIdList((chatIdList) => [
      //   ...chatIdList,
      //   filteredChatData.map((item) => {
      //     return item.id;
      //   }),
      // ]);

      const combinedWithoutDuplicates = combine2ChatNoDuplicate(filteredObject, chatData);

      console.log('messageData', messageData);

      setFilteredChatList(combinedWithoutDuplicates);
      setFilteredMessageList(messageData);
      setAfterCursorSearchedMessage(responseGetMessages.data.messages.meta);
    } else {
      console.log("Error get Messages from DB", responseGetMessages);
    }
    
    setIsLoading(false);
  };

  const onInputChange = (e) => {
    setTextFilter(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
  };

  const chatItemClicks = (id) => {
    setSelectedId(id);
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  useEffect(() => {
    // if (chatID && !loadingOnce && chatList && !isLoading) {
    //   chatItemClick(chatID);
    //   setLoadingOnce(true);
    // }
  }, [isLoading]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    onFilterClick(tab);
  };

  return (
    <React.Fragment>
      <Head title="Chat / Messenger"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <SimpleBar className="">
              <div className="d-flex justify-content-center" style={{ height: "40px", marginLeft: "110px" }}>
                <Nav tabs style={{ border: "none", flexWrap: "nowrap" }}>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "ALL" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("all");
                      }}
                    >
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "unopened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("unopened");
                      }}
                    >
                      Unopened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "opened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("opened");
                      }}
                    >
                      Opened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "pending" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("pending");
                      }}
                    >
                      Pending
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        marginRight: "10px",
                        height: "40px",
                        color: activeTab === "resolved" ? "#4461F2" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        toggleTab("resolved");
                      }}
                    >
                      Resolved
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </SimpleBar>
            <div className="nk-chat-aside-search p-2" style={{ backgroundColor: "#e5e9f2", marginBottom: "15px" }}>
              <div className="form-group">
                <div className="form-control-wrap d-flex">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <Input
                    type="text"
                    className="form-round"
                    id="default-03"
                    placeholder="Search by name"
                    onChange={(e) => onInputChange(e)}
                    style={{ backgroundColor: "white" }}
                  />
                  <div
                    className="btn btn-round btn-icon btn-light dropdown-toggle ml-2"
                    onClick={() => setIsModalTagFilterOpen(true)}
                  >
                    <ChatModalFilter
                      cookies={cookies}
                      isModalTagFilterOpen={isModalTagFilterOpen}
                      setIsModalTagFilterOpen={setIsModalTagFilterOpen}
                      setFilterTag={setFilterTag}
                      filterTag={filterTag}
                    />
                    <Icon name="filter-alt"></Icon>
                    {filterTag.length > 0 ? <span className="status dot dot-lg dot-success"></span> : <></>}
                  </div>
                </div>
              </div>
            </div>
              <ChatAsideBody
                cookies={cookies}
                onInputChange={onInputChange}
                setCheckedTag={setCheckedTag}
                isModalTagAssignOpen={isModalTagAssignOpen}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                afterCursorChat={afterCursorChat}
                setAfterCursorChat={setAfterCursorChat}
                filterTab={filterTab}
                isSearch={searchText !== "" ? true : false}
                afterCursorSearchedMessage={afterCursorSearchedMessage}
                setAfterCursorSearchedMessage={setAfterCursorSearchedMessage}
                searchText={searchText}
                isLoading={isLoading}
                status={activeTab !== "all" ? activeTab.toUpperCase() : ""}
                filterTag={filterTag}
                setContextMessage={setContextMessage}
                roleFunctions={roleFunctions}
              />
          </div>
          {selectedId !== null ? (
            <ReplyContext.Provider
              value={{ value: [replyMessage, setReplyMessage], value2: [contextMessage, setContextMessage] }}
            >
              <ChatBody
                AfterAssign={() => refreshChatList()}
                cookies={cookies}
                mobileView={mobileView}
                setMobileView={setMobileView}
                setSelectedId={setSelectedId}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                isModalTagAssignOpen={isModalTagAssignOpen}
                checkedTag={checkedTag}
                setCheckedTag={setCheckedTag}
                assignMessage={assignMessage}
                userDetail={userDetail}
              />
            </ReplyContext.Provider>
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon name="chat" className="icon-circle icon-circle-xxl bg-white"></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <h6>Please select a chat to start messaging</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>
    </React.Fragment>
  );
};

export default Chat;
