import React, { useState, useEffect, useContext } from "react";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { BlockHead, BlockBetween, BlockHeadContent, BlockTitle, Icon } from "components/Component";
import LeadsMonitoringFilter from "./LeadsMonitoringFilter";
import { useCookies } from "react-cookie";
import { DefaultKeyLeadsFilter } from "data/DefaultLeadFilters";
import { MessagesContext } from "contexts/messagesContext";
import LeadsMonitoringBody from "./LeadsMonitoringBody";
import { serviceGetTicketLeads, serviceGetUserLeads } from "services/leads-monitoring";

const LeadsMonitoring = () => {
    // const [columns, setColumns] = useState(null);
    // const [boardModal, setBoardModal] = useState(false);
    // const [taskModal, setTaskModal] = useState(false);
  const [kanbanFilter, setKanbanFilter] = useState(DefaultKeyLeadsFilter);  
  const [leadsFilter, setLeadsFilter] = useState(DefaultKeyLeadsFilter);
  const [smBtn, setSmBtn] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [loading, setLoading] = useState(true);
  const [departmentsFilter, setDepartmentsFilter] = useState(cookies.departmentId);
  const [dataLeads, setDdataLeads] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [changeOption, setChangeOption] = useState(1);
  const [selectedSort, setSelectedSort] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["lead-monitorings"].access || [];
  const { messagesValue, salesUpdate } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;

  const [userList, setUserList] = useState([]);
  const [originalUserList, setOriginalUserList] = useState([]);
  const [loadMoreId, setLoadMoreId] = useState("");
  const [ticketMetaData, setTicketMetaData] = useState({});

  //filter
  const [filterStatus, setFilterStatus] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [filterDepartmentId, setFilterDepartmentId] = useState();

  useEffect(() => {
    console.log('change filter status');
    refreshUserList(startDate, endDate);
  }, [filterStatus, departmentsFilter]);

  // useEffect(() => {
  //   // refreshData(startDate, endDate);
  //   refreshUserList();
  // }, []);
  
  useEffect(() => {
    console.log('loadMoreId',loadMoreId)
    loadMoreUserList(loadMoreId);
  }, [loadMoreId, setLoadMoreId, ticketMetaData, setTicketMetaData]);

  useEffect(() => {
    // refreshData(startDate, endDate);
  }, [departmentsFilter]);

  useEffect(() => {
    // refreshData(startDate, endDate);
  }, [changeOption]);

  useEffect(() => {
    // refreshData(startDate, endDate);
  }, [selectedSort]);
  
  useEffect(() => {
    console.log('selectedFilterOptions',selectedFilterOptions)
    if(selectedFilterOptions.length === 0){
    }
  }, [selectedFilterOptions, setSelectedFilterOptions]);

  useEffect(() => {
    console.log('filterUser',filterUser)
    if(filterUser.length > 0){
      let newDataFilter = [...originalUserList];
      newDataFilter = newDataFilter.filter((lead) => {
        return filterUser.some((sales) => {
          const salesName = sales.split(" ");
          return lead.firstName === salesName[0] && lead.lastName === salesName[1];
        });
      });

      console.log('newDataFilter', newDataFilter);

      setUserList(newDataFilter);
    }else{
      setUserList(originalUserList);
    }

  }, [filterUser, setFilterUser]);

  const sortDataSales = (data, sortParam) => {
    data.sort((a, b) => {
      let fa = a[sortParam].toLowerCase(),
        fb = b[sortParam].toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return data;
  };

  const sortData = (data, sortParam) => {
    if (sortParam.key === "tickets") {
      data.sort((a, b) => {
        let fa = a[sortParam.val].toLowerCase(),
          fb = b[sortParam.val].toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } else if (sortParam === "default") {
      data.sort((a, b) => {
        let fa = a.updatedAt,
          fb = b.updatedAt;
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort((a, b) => {
        let fa = a.contact[sortParam.val]?.toLowerCase(),
          fb = b.contact[sortParam.val]?.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    }
    return data;
  };

  const colorStatus = (status) => {
    if (status === "UNOPENED") {
      return "danger";
    } else if (status === "RESOLVED") {
      return "success";
    } else if (status === "PENDING") {
      return "warning";
    }
    return "primary";
  };
  
  
  const refreshUserList = async () => {
    setLoading(true);
    const userResp = await serviceGetUserLeads(
      cookies.SIDikey,
      roleFunctions, 
      departmentsFilter, 
      1, 
      50, 
      '' //query
    );

    const usersMaster = [];

    if(userResp.status === 200){
        for (const user of userResp.data.data) {
            // filterStatus
            const respTicket = await serviceGetTicketLeads(cookies.SIDikey, ["Access_Own"], "", user.id, 1, 
              filterStatus
            );
            console.log(respTicket);
            if(respTicket.status === 200){
                const tickets = respTicket.data.data;
                user.tickets = tickets;
                user.ticketCount = respTicket.data.meta.itemCount;
                user.metaData = respTicket.data.meta;
            }

            usersMaster.push(user);

        }
        setUserList(usersMaster);
        if(usersMaster.length >= originalUserList.length){
          setOriginalUserList(usersMaster);
        }
    }
    
    setLoading(false);
  };

  const loadMoreUserList = async (userId) => {
    try {
      const tempList = [...userList];
      const user = tempList.find((user) => user.id === userId);
      
      if (!user) {
        console.error("User not found");
        setLoading(false);
        return;
      }
      
      // filterStatus
      const respTicket = await serviceGetTicketLeads(
        cookies.SIDikey,
        ["Access_Own"],
        "",
        user.id,
        ticketMetaData.page + 1,
        filterStatus
      );
  
      if (respTicket.status === 200) {
        const tickets = respTicket.data.data;
        user.tickets = [...user.tickets, ...tickets];
        user.ticketCount = respTicket.data.meta.itemCount;
        user.metaData = respTicket.data.meta;
        
        // Update the user in the list
        const updatedIndex = tempList.findIndex((u) => u.id === userId);
        if (updatedIndex !== -1) {
          tempList[updatedIndex] = user;
          console.log(user)
        }
      }

  
      setUserList([...tempList]);
      
      // Update originalUserList if needed
      if (tempList.length >= originalUserList.length) {
        setOriginalUserList([...tempList]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error loading user tickets:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    // refresh tickets if there's new tickets
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages]);

  useEffect(() => {
    
  }, [newSales]);

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
    //   refreshData(start, end);
    } else {
    //   refreshData(null, null);
    }
  };

  return (
    <React.Fragment>
      <Head title="Leads Monitoring"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Leads Monitoring</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#toggle"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmBtn(!smBtn);
                  }}
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <LeadsMonitoringFilter
          setLeadsFilter={setLeadsFilter}
          departmentsFilter={departmentsFilter}
          setDepartmentsFilter={setDepartmentsFilter}
          cookies={cookies}
          dataLeads={userList}
          selectedFilterOptions={selectedFilterOptions}
          setSelectedFilterOptions={setSelectedFilterOptions}
          changeOption={changeOption}
          setChangeOption={setChangeOption}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          handleDate={handleDate}
          startDate={startDate}
          endDate={endDate}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          filterUser={filterUser}
          setFilterUser={setFilterUser}
        />

        <LeadsMonitoringBody
            userList={userList}
            loading={loading}
            kanbanFilter={kanbanFilter}
            setLoadMoreId={setLoadMoreId}
            setTicketMetaData={setTicketMetaData}
        ></LeadsMonitoringBody>
      </Content>
    </React.Fragment>
  );
};

export default LeadsMonitoring;
