import React, { useState } from "react";
import LeadsMonitoringCard from "./LeadsMonitoringCard";
import { Icon } from "components/Component";
import { Spinner } from "reactstrap";

const LeadsMonitoringLine = ({ index, user, kanbanFilter, setLoadMoreId, setTicketMetaData }) => {
    const [loading, setLoading] = useState(false);
    
    const handleAddClick = (userId, metaData) => {
        setTicketMetaData(metaData)
        setLoadMoreId(userId)
        setLoading(true);
        // Simulate an async operation
        setTimeout(() => {
            setLoading(false);
            // Add your actual functionality here
        }, 2500);
    };

    return (
        <React.Fragment>
            <div key={index} className={`kanban-board kanban-${'primary'}`}>
                <div className="kanban-title-board">
                    <div className="kanban-title-content mb-1 mt-1">
                        <h6 className="title">{`${user.firstName} ${user.lastName}`}</h6>
                        <span className="badge badge-pill badge-outline-light text-dark">{user.ticketCount}</span>
                    </div>
                    <div className="kanban-title-content">
                        {/* Opsional: Dropdown yang sebelumnya digunakan */}
                    </div>
                </div>
                
                <div className="kanban-drag">
                    { user.ticketCount > 0 ? 
                        user.tickets.map((ticket, index) => 
                            <LeadsMonitoringCard key={index} index={index} ticket={ticket} kanbanFilter={kanbanFilter}/> 
                        ) : <h5>User Has no Leads!</h5>
                    }
                </div>
                
                <div className="text-center mb-2">
                    <button 
                        className="btn btn-icon btn-sm btn-primary" 
                        onClick={()=>handleAddClick(user.id, user.metaData)}
                        disabled={loading}
                    >
                        {loading ? (
                            <Spinner/>
                        ) : (
                            <Icon name="plus"/>
                        )}
                        <p className="px-2">
                            Load More
                        </p>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LeadsMonitoringLine;