import React, { useState, createContext, useEffect } from "react";

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const [chatList, setChatList] = useState([]);
  const [chatIdList, setChatIdList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [selectedChat, setSelectedChat] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [textFilter, setTextFilter] = useState('');
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [queryChatFilter, setQueryChatFilter] = useState('');
  const [isSavedArray, setIsSavedArray] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  useEffect(() => {
    // Using a Map to track unique IDs
    console.log()
  }, [selectedId]);

  useEffect(() => {
    if(textFilter === ''){
      setFilteredChatList([])
    }
  }, [textFilter]);

  useEffect(() => {
    // Using a Map to track unique IDs
    console.log()
  }, [selectedId]);


  
  return (
    <ChatContext.Provider
      value={{
        chatListState: [chatList, setChatList],
        chatListIdState: [chatIdList,setChatIdList],
        salesListState: [salesList,setSalesList],
        selectedChatState: [selectedChat, setSelectedChat],
        selectedIdState: [selectedId, setSelectedId],
        textFilterState: [textFilter,setTextFilter],
        filteredChatListState: [filteredChatList,setFilteredChatList],
        filteredMessageListState: [filteredMessageList, setFilteredMessageList],
        queryChatFilterState: [queryChatFilter, setQueryChatFilter],
        isSavedArray: [isSavedArray, setIsSavedArray],
        isLoadingSearchState: [isLoadingSearch, setIsLoadingSearch],
        chatListData: chatList,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};


  // // Function for deleting a conversation
  // const deleteConvo = (id) => {
  //   let data = chatList;
  //   data = data.filter((item) => item.id !== id);
  //   setChatList([...data]);
  // };

  // // Universal function for any props
  // const propAction = (id, prop) => {
  //   let data = chatList;
  //   const index = data.findIndex((item) => item.id === id);
  //   data[index][prop] = true;
  //   setChatList([...data]);
  // };

  // // Function to add favourite to favourite list
  // const favAction = (id) => {
  //   let data = favData;
  //   const index = data.findIndex((item) => item.id === id);
  //   data[index].fav = true;
  //   setFavData([...data]);
  //   setChatList([...data]);
  // };

  // // Function to change nickame
  // const changeNickname = (id, name) => {
  //   const data = chatList;
  //   const index = data.findIndex((item) => item.id === id);
  //   data[index].nickname = name;
  //   setChatList([...data]);
  // };

  // // Function to change theme
  // const changeTheme = (id, name) => {
  //   const data = chatList;
  //   const index = data.findIndex((item) => item.id === id);
  //   data[index].chatTheme = name;
  //   setChatList([...data]);
  // };

  // // Removes user from group
  // const serviceDeleteUsers = (id, userId) => {
  //   let data = chatList;
  //   const grouped = data.findIndex((item) => item.id === id);
  //   const group = data.find((item) => item.id === id);
  //   const user = group.user.filter((user) => user.id !== userId);
  //   group.user = user;
  //   data[grouped] = group;
  //   setChatList([...data]);
  // };

  // // Makes a user admin
  // const makeAdmin = (id, userId) => {
  //   let data = chatList;
  //   const grouped = data.findIndex((item) => item.id === id);
  //   const group = data.find((item) => item.id === id);
  //   group.user.forEach((element) => {
  //     element.role = "User";
  //   });
  //   const userIndex = group.user.findIndex((item) => item.id === userId);
  //   group.user[userIndex].role = "Admin";
  //   data[grouped] = group;
  //   setChatList([...data]);
  // };

  // // Add a user to a chat
  // const addUserToChat = (id, object) => {
  //   let data = chatList;
  //   const group = data.find((item) => item.id === id);
  //   let newUserGroup = [...group.user, object];
  //   group.user = newUserGroup;
  //   setChatList(data);
  //   let newUserData = userData;
  //   let user = newUserData.filter((el) => el.id !== object.id);
  //   setUserData(user);
  // };