import React, { useState } from "react";
import { Badge } from "reactstrap";
import { Icon } from "components/Component";
import Table from './../../../../components/table/Table';
import moment from "moment";

const LeadsMonitoringCard = ({ index, ticket, kanbanFilter }) => {
  
    /**
     * THIS IS WHERE CARD or CHAT is rendered
     */

    const colorStatus = (status) => {
      if (status === "UNOPENED") {
        return "danger";
      } else if (status === "RESOLVED") {
        return "success";
      } else if (status === "PENDING") {
        return "warning";
      }
      return "primary";
    };

    const [isOpen, setIsOpen] = useState(true);
    const contact = ticket.chat.contact;
  
    return (
      <React.Fragment>
        <div className="mt-2">
          <div className="kanban-item">
            <div className="kanban-item-title mb-1">
              <div className="d-flex align-items-center">
                <Icon name="user-round" className="mr-1 kanban-icon yellow-color" />{" "}
                <h6 className="title">{ticket.chat.name}</h6>
                <p className="title">{ ticket.chat.name.includes('628') ? contact.name : ticket.chat.customerPhone}</p>
              </div>
            </div>
            {kanbanFilter.Status && (
              <ul className="kanban-item-tags mb-1">
                  <li key={index}>
                    <Badge color={colorStatus(ticket.status)}>{ticket.status}</Badge>
                  </li>
              </ul>
            )}
            {kanbanFilter.Address && (
              <div className="kanban-item-text mb-1">
                <p>{contact.address}</p>
              </div>
            )}
            {kanbanFilter.City && (
              <div className="kanban-item-text mb-1" style={{ fontWeight: 600 }}>
                <p>{contact.district}</p>
              </div>
            )}
            <div className="kanban-item-text mb-1">
              <table>
                <tbody>
                  <tr><td>First Interaction</td><td><p>: { moment(ticket.createdAt).format("DD MMM, YYYY - HH:mm") }</p></td></tr>
                  <tr><td>Last Interaction</td><td><p>: {ticket.updatedAt}</p></td></tr>
                </tbody>
              </table>
            </div>
            {kanbanFilter.Email && (
              <div className="kanban-item-text mb-1 d-flex align-items-center">
                <Icon name="emails-fill" className="mr-1" style={{ fontSize: 16, color: "#F73164" }}></Icon>
                <p>{contact?.emails[0] ?? ''}</p>
              </div>
            )}
            {kanbanFilter.Phone && (
              <div className="kanban-item-text mb-1 d-flex align-items-center">
                <Icon name="call-fill" className="mr-1" style={{ fontSize: 16, color: "#F73164" }}></Icon>
                <p>{contact.customerPhone}</p>
              </div>
            )}
            <div className="kanban-item-text mb-1">
              <p><b>{ticket.name ? ticket.name.substring(0,30) + '...' : '-'}</b></p>
            </div>
            {/* <div style={{ cursor: 'pointer' }} onClick={toggleOpen}>
              Show {open ? 'less' : 'more'}
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  };

export default LeadsMonitoringCard;
